import React, { useEffect } from 'react';

const CookieConsent = () => {
  useEffect(() => {
    // Load the external script dynamically
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://csapi.nbtc.go.th/v1/privacy-managers/54057097048/reddot.js?lang=th';
    document.body.appendChild(script);

    // Cleanup to remove script and link on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Add your content here, if necessary, or keep it blank for script-driven functionality */}
    </div>
  );
};

export default CookieConsent;
