import { useState } from 'react';
import { Card, Grid, Divider } from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { format } from 'date-fns';

export default function SDoCTransactionsCard({
	SDoCTransactions,
	isDataLoading = false,
}) {
	const [sortModel, setSortModel] = useState([
		{
			field: 'createDate',
			sort: 'desc',
		},
	]);

	const columnsSDoCTransactions = [
		{
			field: 'refnO1',
			headerName: 'หมายเลขอ้างอิง 1',
			width: 230,
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
		},
		{
			field: 'refnO2',
			headerName: 'หมายเลขอ้างอิง 2',
			width: 230,
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
		},
		{
			field: 'createDate',
			headerName: 'วันที่สร้าง',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: (params) => (
				<Grid>
					{params.value ? format(new Date(params.value), 'dd/MM/yyyy  HH:mm') : ''}
				</Grid>
			),
		},
		{
			field: 'dueDate',
			headerName: 'วันหมดอายุ',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: (params) => (
				<Grid>
					{params.value ? format(new Date(params.value), 'dd/MM/yyyy  HH:mm') : ''}
				</Grid>
			),
		},
		{
			field: 'receiveDate',
			headerName: 'วันที่รับเงิน',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: (params) => (
				<Grid>
					{params.value ? format(new Date(params.value), 'dd/MM/yyyy  HH:mm') : ''}
				</Grid>
			),
		},
		{
			field: 'invoiceNo',
			headerName: 'ใบเสร็จรับเงิน',
			width: 190,
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
		},
		{
			field: 'status',
			headerName: 'สถานะ',
			width: 150,
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params) => {
				let statusText = '';
				let statusColor = '';
				var today = new Date();
				today.setDate(today.getDate() + 1);
				var dueDate = new Date(params.row.dueDate);
				if (dueDate > today && params.row.receiveDate === null && params.row.invoiceNo === null) {
					statusText = 'รอการชำระเงิน';
					statusColor = 'text-yellow-700';
				} else if (dueDate < today && params.row.receiveDate === null && params.row.invoiceNo === null) {
					statusText = 'หมดเวลาชำระเงิน';
					statusColor = 'text-red-700';
				} else if (params.row.receiveDate !== null && params.row.invoiceNo === null) {
					statusText = 'ชำระเงินแล้ว';
					statusColor = 'text-green-700';
				} else if (params.row.receiveDate !== null && params.row.invoiceNo !== null) {
					statusText = 'สำเร็จ';
					statusColor = 'text-green-700';
				}

				return (
					<Grid className="flex flex-row items-center justify-center">
						<div
							className={`inline-block px-3 py-1 rounded ${statusColor}`}
							style={{ whiteSpace: 'nowrap' }}
						>
							{statusText}
						</div>
					</Grid>
				);
			}
		},
	];

	return (
		<Card className="border-t-4 border-card_top_border mb-5">
			<Grid className="flex flex-col justify-center items-start px-5 mb-5">
				{/* Header */}
				<Grid className="flex flex-row mt-4 mb-2">
					<Assignment style={{ fontSize: 22 }} className="mr-1" />
					<Grid className="font-bold text-sm text-card_header_color">
						รายการใบแจ้งหนี้
					</Grid>
				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
				</Grid>

				{/* Files Section */}
				<Grid className="flex flex-col items-start my-2 w-full gap-y-3">
					<Grid>รายละเอียดรายการ</Grid>
					<Grid className="md:px-5" style={{ height: 275, width: '100%' }}>
						<DataGrid
							rows={SDoCTransactions}
							columns={columnsSDoCTransactions}
							pageSize={2}
							rowsPerPageOptions={[2]}
							disableSelectionOnClick
							sortModel={sortModel}
							rowHeight={70}
							components={{
								NoRowsOverlay: () =>
									isDataLoading ? (
										<GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>
									) : (
										<GridOverlay>ไม่พบข้อมูล</GridOverlay>
									),
							}}
						/>
					</Grid>
				</Grid>
				<Grid className="mt-3 mb-1 w-full">
					<Divider />
				</Grid>
			</Grid>
		</Card>
	);
}
