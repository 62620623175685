import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { SendOutlined } from '@material-ui/icons'
import NotifyModal from 'components/NotifyModal'
import FormSelectService from './components/FormSelectService'
import SelectOperatorModal from './components/SelectOperatorModal'
import FormRegisterCompany from './components/RegisterCompany'
import { users, operators } from 'api/index'
import { useHistory } from 'react-router-dom'

import { useStoreActions, useStoreState } from 'store/index'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

const isShowSwal = false

const Identify = () => {
	// const history = useHistory()
	// const { servicePortalToken } = useStoreState(s => s.userModel)
	// const selectedPOA = useStoreState(s => s.poa.selected)

	// const signinSdoc = useStoreActions(a => a.sdocUserModel.signInUser)
	// const { setServicePortalToken, signin: signinQos } = useStoreActions(
	// 	a => a.userModel
	// )
	const history = useHistory()
	const { servicePortalToken, userFromServicePortal } = useStoreState(s => s.userModel)
	const selectedPOA = useStoreState(s => s.poa.selected)
	const POA = useStoreState(s => s.poa.data)
	const actionIdentify = useStoreActions(a => a.userModel.identify)
	const signinSdoc = useStoreActions(a => a.sdocUserModel.signInUser)
	const { setServicePortalToken, signin: signinQos } = useStoreActions(
		a => a.userModel
	)

	const {
		setData: setPOAData,
		setDataDBD,
		setSelected: selectPOA,
	} = useStoreActions(a => a.poa)

	// const { setDataDBD } = useStoreActions(a => a.poa)

	const signinQosWithRegisterCompany = useStoreActions(
		a => a.userModel.signinQosWithRegisterCompany
	)
	// eslint-disable-next-line
	const [loadPoaSuccess, setLoadPoaSuccess] = useState(false)
	// eslint-disable-next-line
	const [selectPOAError, setSelectPOAError] = useState(false)

	const [openNoPOAModal, setOpenNoPOAModal] = useState(false)
	// eslint-disable-next-line
	const [openPOAModal, setOpenPOAModal] = useState(false)
	const [activeRegisterCompany, setActiveRegisterCompany] = useState(false)

	const onSelectService = service => {
		// signinSdoc({ _token: servicePortalToken })
		// 	.then(data => {
		// 		if (data !== null) {
		// 			history.replace('/sdoc')
		// 		}
		// 	})
		// 	.catch(() => {
		// 		alert('error case 1')
		// 	})

		if (POA) {
			setOpenPOAModal(true)
		} else {
			// setOpenNoPOAModal(true)
		}
	}
	const submitRegisterCompany = async payload => {
		await signinQosWithRegisterCompany(payload)
			.then(e => {
				history.replace('/sdoc')
			})
			.catch(e => {
				console.log(e)
			})
	}
	useEffect(() => {
		if (isShowSwal) {
			Swal.fire({
				title: 'ประกาศปิดปรับปรุงระบบ',
				html: `
				<div style="line-height: 33px !important;">
					<p style="text-align:left;text-indent:4ch;margin-bottom:1rem;">ประกาศแจ้งปิดระบบทำการปิดปรับปรุงระบบฉุกเฉิน ขออภัยในความไม่สะดวก ทางสำนักงาน กสทช. จะเร่งดำเนินการเพื่อให้ท่านสามารถยื่นคำขอรับบริการหรืออนุญาตได้โดยเร็วที่สุด</p>
					<p style="text-align:right;text-indent:4ch;margin-bottom:1rem;">สำนักงาน กสทช.</p>
				</div>
			 `,
				icon: 'warning',
				width: '700',
				showCancelButton: false,
				confirmButtonText: 'ข้าพเจ้าได้อ่านและรับทราบแล้ว',
				reverseButtons: true,
			});
		}
		// const urlSearchParams = new URLSearchParams(window.location.search)
		// const params = Object.fromEntries(urlSearchParams.entries())

		// setServicePortalToken(params._token)

		// signinSdoc({ _token: params._token })
		// 	.then(data => {
		// 		if (data !== null) {
		// 			history.replace('/sdoc')
		// 		}
		// 	})
		// 	.catch(() => {
		// 		alert('error case 1')
		// 	})
		const urlSearchParams = new URLSearchParams(window.location.search)
		const params = Object.fromEntries(urlSearchParams.entries())
		async function getListPOASDoC(token) {
			const res = await users.getMyPOASDoC(token)
			if (res) {
				setPOAData(res)
				setLoadPoaSuccess(true)
			} else {
				setPOAData(null)
				setLoadPoaSuccess(true)
			}
		}

		if (!params._token) {
			// history.replace('/sign-in')
		}
		setServicePortalToken(params._token)

		actionIdentify({ _token: params._token })
			.then(data => { })
			.catch(() => {
				setOpenNoPOAModal(true)
			})

		getListPOASDoC(params._token)

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (selectedPOA !== null && selectedPOA.operator !== null) {
			if (selectedPOA !== 0) {
				signinSdoc({ _token: servicePortalToken, poa: selectedPOA.juristicNo })
					.then(data => {
						if (data !== null) {
							history.replace('/sdoc')
						}
					})
					.catch(() => {
						alert('error case 1')
					})
			} else {
				signinSdoc({ _token: servicePortalToken, poa: null })
					.then(data => {
						if (data !== null) {
							history.replace('/sdoc')
						}
					})
					.catch(() => {
						alert('error case 1')
					})
			}
		} else if (selectedPOA !== null && selectedPOA.operator === null) {
			operators
				.getOperatorInfoDBD({ taxno: selectedPOA.juristicNo, pid: userFromServicePortal.UserProfile.NationalID })
				.then(e => {
					if (e.status !== 200) {
						setSelectPOAError(true)
						return false
					} else {
						return e
					}
				})
				.then(e => e.data)
				.then(data => {
					if (data?.data1000101001) {
						const foundCompany = data.data1000101001.ResultList[0]
						setDataDBD(foundCompany)
						setOpenPOAModal(false)
						setActiveRegisterCompany(true)
					} else {
						const blankData = {
							"ResultList": [
								{
									"JuristicType":  null,
									"JuristicID": selectedPOA.juristicNo,
									"OldJuristicID":  null,
									"RegisterDate":  null,
									"JuristicName_TH":  null,
									"JuristicName_EN":  null,
									"RegisterCapital":  null,
									"PaidRegisterCapital": null,
									"NumberOfObjective":  null,
									"NumberOfPageOfObjective":  null,
									"JuristicStatus":  null,
									"StandardID":  null,
									"CommitteeInformations": [
									],
									"AuthorizeDescriptions": [
									],
									"StandardObjectives": [
									],
									"AddressInformations": [
										{
											"Sequence": null,
											"AddressName": null,
											"FullAddress": null,
											"Building": null,
											"RoomNo": null,
											"Floor": null,
											"VillageName": null,
											"AddressNo": null,
											"Moo": null,
											"Soi": null,
											"Road": null,
											"Tumbol": null,
											"Ampur": null,
											"Province": null,
											"Phone": null,
											"Email": null
										}
									]
								}
							]
						}
						const foundCompany = blankData.ResultList[0];
						setDataDBD(foundCompany)
						setOpenPOAModal(false)
						setActiveRegisterCompany(true)
					}
				})
				.catch(err => {
					console.error(err)
				})
		}
		// eslint-disable-next-line
	}, [selectedPOA])

	return (
		<Grid className="h-screen flex flex-col items-center justify-center sign-in-container p-3">
			{loadPoaSuccess && !activeRegisterCompany && (
				<FormSelectService onSelectService={onSelectService} />
			)}
			{activeRegisterCompany && (
				<FormRegisterCompany
					onSubmit={submitRegisterCompany}
					goBack={() => {
						setActiveRegisterCompany(false)
					}}
				/>
			)}
			<NotifyModal
				title="โปรดทราบ"
				content="คุณไม่ได้รับสิทธิ์การเข้าใช้งานระบบ SDoC"
				// content="ขณะนี้ระบบทำการปิดปรับปรุง โปรดลองใหม่อีกครั้งในภายหลัง เวลา 20.00 น."
				header_icon={
					<SendOutlined style={{ fontSize: 20 }} className="mr-1 text-white" />
				}
				open={openNoPOAModal}
				onClose={() => {
					setOpenNoPOAModal(false)
					history.replace('/signin')
				}}
			/>

			<SelectOperatorModal
				title="กรุณาเลือก"
				content="คุณยังไม่ได้รับสิทธ์การมอำนาจจากบริษัท กรณีข้อมูลผิดพลาด กรุณาติดต่อเจ้าหน้าที่ 02-xxx-xxxx"
				header_icon={
					<SendOutlined style={{ fontSize: 20 }} className="mr-1 text-white" />
				}
				isError={selectPOAError}
				open={openPOAModal}
				onClose={() => {
					selectPOA(null)
					setOpenPOAModal(false)
					setSelectPOAError(false)
				}}
			/>
		</Grid>
	)
}

export default Identify
