import { forwardRef, useRef, useEffect, useState } from 'react'
import {
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Slide,
	Button,
	Grid,
	useMediaQuery,
	IconButton,
	TextField,
	Divider,
} from '@material-ui/core'
import 'date-fns'

import { Close, PersonAdd, Edit } from '@material-ui/icons'
import { useTheme } from '@material-ui/core/styles'
import { useStoreActions } from 'store'
export default function OrganizationUserModal({
	open,
	handleButton,
	onClose,
	userModalType,
	userInfo,
	onFillAddUserInfo,
	// Save Button Logic
	saveButtonAdd,
	saveButtonEdit,
	// Admin Role
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const descriptionElementRef = useRef(null)

	const onSearchOperator = useStoreActions(
		a => a.operatorModel.searchOperatorDBD
	)
	const [form, setForm] = useState({ taxno: '' })
	const [resultDBD, setResultDBD] = useState(null)

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef
			if (descriptionElement !== null) {
				descriptionElement.focus()
			}
		}
		setForm({ taxno: '' })
		setResultDBD(null)
	}, [open])

	const typeGenerator = _userModalType => {
		if (_userModalType === 'add')
			return {
				label: 'เพิ่มผู้ประกอบการ',
				icon: <PersonAdd htmlColor="#fff" className="mr-1" />,
			}
		if (_userModalType === 'edit')
			return {
				label: 'แก้ไขข้อมูลผู้ประกอบการ',
				icon: <Edit htmlColor="#fff" className="mr-1" />,
			}

		return { label: '', icon: '' }
	}

	const onSearch = () => {
		onSearchOperator({ taxno: form.taxno }).then(e => {
			if (e?.data1000101001 ) {
				const foundCompany = e.data1000101001.ResultList[0]
				setResultDBD(foundCompany)
			}
			setResultDBD("ไม่พบข้อมูล")
		})
	}

	return (
		<Dialog
			className="w-full"
			open={open}
			onClose={onClose}
			maxWidth="lg"
			fullWidth
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			scroll="paper">
			<DialogTitle className="bg-card_top_border">
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Grid className="flex flex-row items-center">
						{typeGenerator(userModalType).icon}
						<Typography variant="h6" className="text-white">
							{typeGenerator(userModalType).label}
						</Typography>
					</Grid>
					<IconButton onClick={onClose} style={{ color: '#fff' }}>
						<Close />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent className="h-full" dividers>
				<Grid className="my-3">
					<Typography>ข้อมูลผู้ประกอบการ</Typography>
				</Grid>
				<Grid>
					<Divider />
				</Grid>
				<Grid className="flex flex-col mt-5">
					<Grid className="flex flex-col lg:flex-row items-center gap-x-8 gap-y-5 lg:gap-y-0">
						<Grid className="w-full lg:w-4/12">
							<Grid className="flex flex-row">
								<Typography>เลขประจำตัวผู้เสียภาษี</Typography>
								<Typography className="text-red-500">*</Typography>
							</Grid>

							<TextField
								className="w-full"
								variant="outlined"
								size="small"
								name="taxno"
								value={form.taxno}
								onChange={e => setForm({ ...form, taxno: e.target.value })}
							/>
						</Grid>
						<Grid className="w-full lg:w-4/12">
							<Button
								style={{ marginTop: 20 }}
								disabled={
									userModalType === 'add' ? saveButtonAdd : saveButtonEdit
								}
								onClick={onSearch}
								color="primary"
								variant="contained">
								ค้นหา
							</Button>
						</Grid>
					</Grid>

					<Grid className="flex flex-col lg:flex-row items-center gap-x-8 gap-y-5 lg:gap-y-0">
						<pre style={{ margin: 20 }}>{resultDBD}</pre>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="flex-end" alignItems="center">
					<Grid item className="p-3 w-3/12">
						<Button
							fullWidth
							disabled={
								userModalType === 'add' ? saveButtonAdd : saveButtonEdit
							}
							onClick={handleButton}
							color="primary"
							variant="contained">
							บันทึก
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const Transition = forwardRef(function Transtition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})
