import React, { useState, useEffect, useMemo, useCallback } from 'react'

import {
	Grid,
	Card,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Select,
	MenuItem,
	IconButton,
	Checkbox,
	ListItemText,
	Switch,
	FormControlLabel,
	Tooltip,

} from '@material-ui/core'
import { DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid'
import {
	ListAlt,
	Description,
	NoteAdd,
	Cancel,
	PlaylistAddCheck,
	Menu,
	CheckCircle,
	SyncAlt,
	Search,
	Close,
	ArrowBack,
	Refresh,
} from '@material-ui/icons'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import SelectAssignAdminModal from 'components/sdoc-admin/manage/select-assign-admin-modal/index'
import SignSignatureModal from 'components/sdoc-admin/manage/sign-signature-modal/index'
import SureModal from 'components/SureModal'
import { useStoreState } from '../../../../store/index'
import { useStoreRehydrated } from '@store'

import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'

import { sdoc, sdocAdmin, admin, adminQoS } from 'api/index'

const useStyles = makeStyles(() => {
	return {
		details_button: {
			backgroundColor: '#466AE9',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#466AE9',
			},
		},
		common_button: {
			backgroundColor: '#eee',
			color: '#000',
			'&:hover': {
				backgroundColor: '#eee',
			},
		},
		approve_button: {
			backgroundColor: '#1DBF73',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#1DBF73',
			},
		},
		wait_button: {
			backgroundColor: '#F6B50B',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#F6B50B',
			},
		},
		reject_button: {
			backgroundColor: '#DB4D44',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#DB4D44',
			},
		},
		updated_button: {
			backgroundColor: '#466AE9',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#466AE9',
			},
		},
		cancel_button: {
			backgroundColor: '#DB4D44',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#DB4D44',
			},
		},
		preapprove_button: {
			backgroundColor: '#46E9A4',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#46E9A4',
			},
		},
		waiting_assign_button: {
			backgroundColor: '#FAFAD2',
			color: '#000',
			'&:hover': {
				backgroundColor: '#FAFAD2',
			},
		},

	}
})

// Page Description: สถานะการยื่นแบบหนังสือรับรองตนเอง
export default function SDOCStatus() {
	const isRehydrated = useStoreRehydrated()
	const history = useHistory()
	const classes = useStyles()
	const myInfo = useStoreState(
		s => s.sdocAdminModel.adminMESDOC
	)

	const [rowsWaitStatus, setRowsWaitStatus] = useState([])
	const [isDataLoading, setIsDataLoading] = useState(false)
	const [RowsData, setRowsData] = useState([])
	const [myRole, setMyRole] = useState(null)
	const [myID, setMyID] = useState(null)
	const [checkerAdminList, setCheckerAdminList] = useState([])
	const [signatureAdminList, setSignatureAdminList] = useState([])
	const [sDocID, setSDocID] = useState(null)
	const [checkerAdminID, setCheckerAdminID] = useState('')
	const [selectedSignature, setSelectedSignature] = useState(null)

	// Sure Modal
	const [openSubmitSDocSureModal, setOpenSubmitSDocSureModal] = useState(false)
	const [submitType, setSubmitType] = useState('approve')
	const [remark, setRemark] = useState('')
	const [currentActionSDoc, setCurrentActionSDoc] = useState('')
	const [sortModel, setSortModel] = useState([
		{
			field: 'runningNumber',
			sort: 'desc',
		},
	]);

	const [openModalAssignAdmin, setOpenModalAssignAdmin] = useState(false)
	const [openModalAssignSignature, setOpenModalAssignSignature] =
		useState(false)

	const sureModalTitle = useMemo(
		() => {
			if (submitType === 'approve') {
				return 'อนุมัติแบบหนังสือรับรองตนเอง';
			} else if (submitType === 'reject') {
				return 'ไม่อนุมัติแบบหนังสือรับรองตนเอง';
			} else if (submitType === 'requestAdditionDoc') {
				return 'ขอเอกสารเพิ่มเติม';
			} else if (submitType === 'requestModifyInfo') {
				return 'ขอปรับปรุงข้อมูล';
			} else if (submitType === 'rejectchecker') {
				return 'ไม่อนุมัติออกหนังสือรับรองตนเองและส่งกลับไปยังผู้ตรวจสอบ';
			}
		},
		[submitType]
	)

	// filter Dialog / Modal
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [searchCreate, setSearchCreate] = useState('');
	const [searchLastUpdated, setSearchLastUpdated] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const [isSaveSearch, setIsSaveSearch] = useState(true);

	const statusOptions = [
		// { value: '0', label: 'ทั้งหมด' },
		{ value: '1', label: 'รอมอบหมาย' },
		{ value: '2', label: 'รอตรวจสอบ/เพิ่มเอกสารแล้ว/ปรับปรุงข้อมูลแล้ว' },
		{ value: '3', label: 'ขอเอกสารเพิ่ม/ขอปรับปรุงเอกสาร' },
		{ value: '4', label: 'รอออกใบรับรอง' },
		{ value: '5', label: 'อนุมัติ' },
		{ value: '6', label: 'ไม่อนุมัติ' },
		{ value: '8', label: 'รอชำระเงิน' },
		{ value: '82', label: 'เลยกำหนดชำระ' },
		{ value: '9', label: 'รอออกใบเสร็จรับเงิน' },
		{ value: '10', label: 'ยกเลิกคำขอ' },
		{ value: '11', label: 'เลยระยะเวลา' },
	];
	const toggleDialog = () => {
		setIsDialogOpen(!isDialogOpen);
	};
	const handleSearchChange = (event) => {
		const { id, value } = event.target;
		setSearchQuery((prevSearchQuery) => {
			const updatedQuery = [...prevSearchQuery];
			const existingItemIndex = updatedQuery.findIndex((item) => item.id === id);
			if (existingItemIndex !== -1) {
				updatedQuery[existingItemIndex] = { id, value };
			} else {
				updatedQuery.push({ id, value });
			}
			return updatedQuery;
		});
		// console.log(searchQuery);
	};

	const handleStatusChange = (event) => {
		setSelectedStatus(event.target.value);
	};
	const handleCreateChange = (event) => {
		setSearchCreate(event.target.value);
	};
	const handleLastUpdatedChange = (event) => {
		setSearchLastUpdated(event.target.value);
	};
	const handleSaveSearch = () => {
		setIsSaveSearch(!isSaveSearch);
	};


	const contentSureModal = useMemo(
		() => {

			if (submitType === 'approve') {
				return <div className="text-center">ยืนยันอนุมัติแบบหนังสือรับรองตนเอง</div>
			} else if (submitType === 'reject') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="text"
					label="เหตุผลที่ไม่อนุมัติ"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'requestAdditionDoc') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="textarea"
					label="เอกสารที่ขอเพิ่มเติม"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'requestModifyInfo') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="textarea"
					label="ขอปรับปรุงข้อมูล"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'rejectchecker') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="text"
					label="เหตุผลที่ไม่อนุมัติ และส่งกลับไปยังผู้ตรวจสอบ"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			}
		},
		[submitType, remark]
	)

	const onOpenModalAssignAdmin = () => {

		setOpenModalAssignAdmin(!openModalAssignAdmin)
	}

	const handleIssueBtn = e => {
		setSDocID(e)
		adminQoS.getSignature().then(e => {
			setSignatureAdminList(e)

			setOpenModalAssignSignature(true)
		})
	}

	const onClickAssignAdmin = sDocID => {
		setSDocID(sDocID)
		setCheckerAdminID('')
		onOpenModalAssignAdmin()
	}

	const onChangeCheckerAdminID = event => {
		setCheckerAdminID(event.target.value)
	}

	const onChangeSignature = event => {
		setSelectedSignature(event.target.value)
	}

	const createWaitingRow = (dataSDocList, i) => {
		const rowData = {
			id: dataSDocList[i].id,
			brandName: dataSDocList[i].brandName,
			operator:
				(dataSDocList[i].sDocUser.prefix ?? '') +
				dataSDocList[i].sDocUser.firstname +
				' ' +
				dataSDocList[i].sDocUser.lastname,
			submitDate: dataSDocList[i].createDate,
			statusID: dataSDocList[i].sDocStatusID,
			// statusID: (i % 6) + 1,
			statusName: dataSDocList[i].sDocStatus.statusName,
			approver: dataSDocList[i].approver
				? dataSDocList[i].approver.firstname +
				' ' +
				dataSDocList[i].approver.lastname
				: 'ยังไม่ได้มอบหมาย',
			productModel: dataSDocList[i].productModel,
			runningNumber: dataSDocList[i].runningNo,
			requestAddtionDocStatusID: dataSDocList[i].requestAddtionDocStatusID,
			operatorInfo: dataSDocList[i].senderType === 'organization' ? dataSDocList[i].operatorTitle : null,
			transactions: dataSDocList[i].sDocTransactions,
		}
		return rowData
	}

	const getSDocStatusList = async () => {
		// Get Status List
		const resStatusList = await sdoc.getListsStatus()
		const dataStatusList = resStatusList.data
		const statusListTemp = []
		if (resStatusList) {
			if (resStatusList.status === 200) {
				for (let i = 0; i < dataStatusList.length; i++) {
					statusListTemp.push({
						id: dataStatusList[i].id,
						statusName: dataStatusList[i].statusName,
					})
				}
			}
		}
		return statusListTemp
	}

	const getCheckerAdminList = async () => {
		const resAdminList = await sdocAdmin.getAdminList()

		const adminListTemp = []
		if (resAdminList) {
			if (resAdminList.status === 200) {
				const dataAdminList = resAdminList.data
				for (let i = 0; i < dataAdminList.length; i++) {
					const adminAccess = dataAdminList[i].adminAccess[0] ?? null
					if (
						adminAccess.dataportalServiceID === 2 &&
						// (adminAccess.adminRoleID === 11 || adminAccess.adminRoleID === 15) // show exclusive admin
						( adminAccess.adminRoleID === 11 ) // not show exclusive admin
					) {
						adminListTemp.push({
							id: dataAdminList[i].id,
							name:
								dataAdminList[i].firstname + ' ' + dataAdminList[i].lastname,
						})
					}
				}

				setCheckerAdminList(adminListTemp)
			}
		}
	}

	const openDB = () => {
		return new Promise((resolve, reject) => {
			// const deleteRequest = indexedDB.deleteDatabase('MyDatabase');
			const request = window.indexedDB.open('NBTCSDoCALL', 1);

			request.onerror = (event) => {
				console.error("Error opening database", event.target.error);
				reject(event.target.error);
			};

			request.onupgradeneeded = (event) => {
				const db = event.target.result;

				// Create an object store (similar to a table in relational databases)
				if (!db.objectStoreNames.contains('SDocList')) {
					db.createObjectStore('SDocList', { keyPath: 'id' });
				}

				if (!db.objectStoreNames.contains('SDocListTimeout')) {
					db.createObjectStore('SDocListTimeout', { keyPath: 'id' });
				}

				console.log("Database upgrade complete");
			};

			request.onsuccess = (event) => {
				const db = event.target.result;
				resolve(db);
			};
		});
	};

	const addDataToDBWithTimeout = (data, timeout) => {
		openDB().then((db) => {
			const transaction = db.transaction(['SDocList'], 'readwrite');
			const transaction2 = db.transaction(['SDocListTimeout'], 'readwrite');
			const objectStore = transaction.objectStore('SDocList');
			const objectStore2 = transaction2.objectStore('SDocListTimeout');

			transaction.oncomplete = () => {
				console.log("Data added to IndexedDB with timeout");
			};

			transaction.onerror = (event) => {
				console.error("Error adding data to IndexedDB", event.target.error);
			};

			transaction.onabort = (event) => {
				console.error("Transaction aborted", event.target.error);
			};
			const vtimeout = new Date().getTime() + timeout;
			let vtimeoutData = {
				id: 1,
				timeout: vtimeout
			}
			objectStore2.add(vtimeoutData);
			console.log("Data Timeout Set to:", vtimeoutData)
			data.forEach((item) => {
				try {
					const request = objectStore.add(item);

					request.onsuccess = () => {
						// console.log("Data added:", item);
					};

					request.onerror = (event) => {
						console.error("Error adding data to IndexedDB", event.target.error);
					};
				} catch (error) {
					console.error("Error within transaction:", error);
					transaction.abort();
				}
			});
		}).catch((error) => {
			console.error("Error opening database", error);
		});
	};

	const getDataFromDBWithTimeout = () => {
		return new Promise((resolve, reject) => {
			openDB().then((db) => {
				const transaction = db.transaction(['SDocList'], 'readonly');
				const transaction2 = db.transaction(['SDocListTimeout'], 'readonly');
				const objectStore = transaction.objectStore('SDocList');
				const objectStore2 = transaction2.objectStore('SDocListTimeout');
				const currentTime = Date.now();
				const request2 = objectStore2.getAll();
				request2.onsuccess = (event) => {
					let data2 = event.target.result;
					if (data2.length <= 0) {
						return [];
					}
					data2 = data2[0].timeout;
					if (currentTime > data2) {
						console.log("Data Expired, clearing DB");
						clearSDocList();
						return [];
					}
				};

				const request = objectStore.getAll();

				request.onsuccess = (event) => {
					const data = event.target.result;
					resolve(data);
				};

				request.onerror = (event) => {
					console.error("Error retrieving data from IndexedDB", event.target.error);
					reject(event.target.error);
				};
			}).catch((error) => {
				console.error("Error opening database", error);
				reject(error);
			});
		});
	};

	const clearSDocList = () => {
		openDB().then((db) => {
			const transaction = db.transaction(['SDocList'], 'readwrite');
			const objectStore = transaction.objectStore('SDocList');
			const transaction2 = db.transaction(['SDocListTimeout'], 'readwrite');
			const objectStore2 = transaction2.objectStore('SDocListTimeout');

			const request = objectStore.clear();
			const request2 = objectStore2.clear();

			request.onsuccess = () => {
				console.log("SDocList cleared successfully");
			};

			request.onerror = (event) => {
				console.error("Error clearing SDocList", event.target.error);
			};
		}).catch((error) => {
			console.error("Error opening database", error);
		});
	};

	const forcegetSDOCWaitStatusList = async () => {
		setIsDataLoading(true);
		setRowsWaitStatus([]);
		const resList = await sdoc.getAllLists();
		const apiDataSDocList = resList.data;
		if (resList && resList.status === 200) {
			const timeout = 15 * 60 * 1000;
			const rowsTemp = [];
			for (let i = 0; i < apiDataSDocList.length; i++) {
				rowsTemp.push(createWaitingRow(apiDataSDocList, i));
			}
			setRowsData(rowsTemp);
			setRowsWaitStatus(rowsTemp);
			setIsDataLoading(false); // Set loading state after updating the rows
			getCheckerAdminList();

			// Store data in IndexedDB with timeout
			clearSDocList();
			addDataToDBWithTimeout(apiDataSDocList, timeout);
		}
	}

	const getSDOCWaitStatusList = useCallback(async () => {
		setIsDataLoading(true);
		getSDocStatusList();

		await admin.me().then(async (event) => {
			if (event) {
				const dataMe = event.data;
				setMyRole(dataMe.roleID);
				setMyID(dataMe.id);

				const timeout = 15 * 60 * 1000;

				// Retrieve data from IndexedDB with timeout management
				const dataSDocList = await getDataFromDBWithTimeout();
				if (dataSDocList && dataSDocList.length > 0) {
					const rowsTemp = [];
					for (let i = 0; i < dataSDocList.length; i++) {
						rowsTemp.push(createWaitingRow(dataSDocList, i));
					}
					setRowsData(rowsTemp);
					setRowsWaitStatus(rowsTemp);
					setIsDataLoading(false); // Set loading state after updating the rows
					getCheckerAdminList();
					return; // Exit the function since data was retrieved from IndexedDB
				}
				// Fetch data from API if not available in IndexedDB
				const resList = await sdoc.getAllLists();
				const apiDataSDocList = resList.data;

				if (resList && resList.status === 200) {
					const rowsTemp = [];
					for (let i = 0; i < apiDataSDocList.length; i++) {
						rowsTemp.push(createWaitingRow(apiDataSDocList, i));
						// ---> Add Data to Array
						// Not show status === 'สำเร็จ'
					}
					setRowsData(rowsTemp);
					setRowsWaitStatus(rowsTemp);
					setIsDataLoading(false); // Set loading state after updating the rows
					getCheckerAdminList();

					// Store data in IndexedDB with timeout
					clearSDocList();
					addDataToDBWithTimeout(apiDataSDocList, timeout);
				}
			}
		});
	}, []);
	// filter 
	const onSearchResult = () => {
		let searchedData = RowsData;
		const savedFilters = {
			searchQuery: searchQuery,
			searchCreate: searchCreate,
			selectedStatus: selectedStatus
		};

		// Save filters to localStorage if isSaveSearch is true
		if (isSaveSearch) {
			localStorage.setItem('savedFiltersAll', JSON.stringify(savedFilters));
		} else {
			localStorage.removeItem('savedFiltersAll');
		}
		if (searchQuery)
			// console.log("searchQuery >> ", searchQuery)
			searchQuery.forEach((query) => {
				const { id, value } = query;
				if (value !== '' && value !== null && value !== undefined && value.length > 0) {
					// console.log("id >> ", id, " value >> ", value)
					searchedData = searchedData.filter((item) => {
						const itemValue = item[id];
						// console.log("itemValue >> ", itemValue);
						// console.log("typeof itemValue >> ", typeof itemValue);
						if (typeof itemValue === 'string') {
							return itemValue.toLowerCase().includes(value.toLowerCase());
						} else if (typeof itemValue === 'object') {
							return false;
						} else {
							return itemValue.toString().includes(value);
						}
					});
				}
			});

		if (searchCreate !== '') {
			searchedData = searchedData.filter((item) => {
				const itemDate = new Date(item.submitDate.split('T')[0]);
				const searchDate = new Date(searchCreate);
				return itemDate.getTime() === searchDate.getTime();
			});
		}
		if (searchLastUpdated !== '') {
			searchedData = searchedData.filter((item) => {
				const itemDate = new Date(item.lastUpdate.split('T')[0]);
				const searchDate = new Date(searchLastUpdated);
				return itemDate.getTime() === searchDate.getTime();
			});
		}
		if (selectedStatus.length > 0 && selectedStatus[0] !== '0') {
			searchedData = searchedData.filter(
				(item) => selectedStatus.includes(item.statusID.toString())
			);
		}

		const mergedSearched = searchedData;
		setRowsWaitStatus(mergedSearched);
		setIsDialogOpen(false);
		setIsSearching(true);
		return;
	};

	// Function to apply saved filters on component mount
	const applySavedFilters = () => {
		const savedFilters = localStorage.getItem('savedFiltersAll');

		if (savedFilters) {
			const { searchQuery, searchCreate, searchLastUpdated, selectedStatus } = JSON.parse(savedFilters);
			// Apply the saved filters to your state variables
			setSearchQuery(searchQuery);
			setSearchCreate(searchCreate);
			setSelectedStatus(selectedStatus);
			setIsSearching(true);
			setIsSaveSearch(true);
		}
	};

	// Call applySavedFilters when the component mounts
	useEffect(() => {
		applySavedFilters();
	}, [isSearching]);
	useEffect(() => {
		if (RowsData && isSearching) {
			onSearchResult();
		}
	}, [RowsData, isSearching]); // Call onSearchResult when rowsData or isSearching changes



	const onSearchResultClear = () => {
		setSearchQuery([]);
		setSearchCreate('');
		setSelectedStatus([]);
		setRowsWaitStatus(RowsData);
		setIsSearching(false);
		setIsSaveSearch(false);

		// Remove the saved filters from localStorage
		localStorage.removeItem('savedFiltersAll');

		return;
	};

	const onDeny = useCallback(
		async sDocID => {
			const res = await sdocAdmin.denySDoc(sDocID, remark)
			if (res && res.status === 200) {
				getSDOCWaitStatusList()
				setOpenSubmitSDocSureModal(false)
				setRemark('');
			}
		},
		[getSDOCWaitStatusList, remark]
	)

	const sendToIssue = useCallback(
		async sDocID => {
			const res = await sdocAdmin.sendToIssue(sDocID)
			if (res && res.status === 200) {
				getSDOCWaitStatusList()
				setOpenSubmitSDocSureModal(false)
			}
		},
		[getSDOCWaitStatusList]
	)

	const onSubmitApproval = useCallback(() => {
		if (submitType === 'approve') {
			sendToIssue(currentActionSDoc)
		} else if (submitType === 'reject') {
			onDeny(currentActionSDoc)
		} else if (submitType === 'requestAdditionDoc') {
			onRequestAddition(currentActionSDoc);
		} else if (submitType === 'requestModifyInfo') {
			onRequestModifyInfo(currentActionSDoc);
		} else if (submitType === 'rejectchecker') {
			onDenyChecker(currentActionSDoc)
		}
	}, [onDeny, sendToIssue, currentActionSDoc, submitType])

	const onAssignAdmin = async () => {
		const res = await sdocAdmin.assignAdmin(sDocID, checkerAdminID)
		if (res && res.status === 200) {
			Swal.fire({
				title: 'มอบหมายงานสำเร็จ',
				text: '',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
			setCheckerAdminID('')
			onOpenModalAssignAdmin()
			getSDOCWaitStatusList()
		}
	}
	const onAssignSignature = async () => {
		const res = await sdocAdmin.approveSDoc(sDocID, myInfo.id)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
			setOpenModalAssignSignature(false)
			setRemark('');
		}
	}

	const onRequestAddition = async sDocID => {
		const res = await sdocAdmin.requestAddition(sDocID, remark)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
			setOpenSubmitSDocSureModal(false);
			setRemark('');
		}
	}
	const onRequestModifyInfo = async sDocID => {
		const res = await sdocAdmin.requestModifyInfo(sDocID, remark)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
			setOpenSubmitSDocSureModal(false);
			setRemark('');
		}
	}

	const onDenyChecker = useCallback(
		async sDocID => {
			const res = await sdocAdmin.denyCheckerSDoc(sDocID, remark)
			if (res && res.status === 200) {
				getSDOCWaitStatusList()
				setOpenSubmitSDocSureModal(false)
				setRemark('');
			}
		},
		[getSDOCWaitStatusList, remark]
	)

	const onRejectJob = async sDocID => {
		const res = await sdocAdmin.rejectJob(sDocID)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
		}
	}
	const handleSortChange = (model) => {
		if (isRehydrated && model[0] !== sortModel[0])
			setSortModel(model);
	};
	useEffect(() => {
		if (isRehydrated) {
			getSDOCWaitStatusList()

		}
		//eslint-disable-next-line
	}, [isRehydrated])

	var columnsWaitingStatus = [
		{
			field: 'runningNumber',
			headerName: 'เลขที่คำขอ',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'brandName',
			headerName: 'ตราอักษร (Brand Name)',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'productModel',
			headerName: 'รุ่นของผลิตภัณฑ์',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'operator',
			headerName: 'ผู้ยื่นเอกสาร',
			width: 200,
			headerAlign: 'left',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params) => (
				<Grid style={{ lineHeight: "normal", whiteSpace: "normal" }}>
					{/* {params.row.operator}<br />{params.row.operatorInfo && ('แทน ' + params.row.operatorInfo)} */}
					{params.row.operator}
				</Grid>
			),
			// valueGetter: (params) => {
			// 	if (params.row.operatorInfo) {
			// 		return params.value + ' แทน ' + params.row.operatorInfo + ')'
			// 	}
			// },
		},
		{
			field: 'operatorInfo',
			headerName: 'ยื่นในนาม',
			width: 200,
			headerAlign: 'left',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params) => (
				<Grid style={{ lineHeight: "normal", whiteSpace: "normal" }}>
					{params.row.operatorInfo && (params.row.operatorInfo)}
				</Grid>
			),
		},
		{
			field: 'approver',
			headerName: 'ผู้ได้รับมอบหมายงาน',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'submitDate',
			headerName: 'วันที่ยื่นแบบ',
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => (
				<Grid className="">
					{/* {params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : ''} */}
					{params.value ? params.value : ''}
				</Grid>
			),
			valueGetter: params => {
				if (params.row.submitDate) {
					return format(new Date(params.value), 'dd/MM/yyyy HH:mm')
				}
			}

		},
		// {
		// 	field: 'submitDateB',
		// 	headerName: 'วันที่ยื่นแบบ(สำหรับค้นหา)',
		// 	width: 150,
		// 	headerAlign: 'left',
		// 	align: 'left',
		// 	sortable: true,
		// 	editable: false,
		// 	hide: true,
		// 	renderCell: params => (
		// 		<Grid className="">
		// 			{/* {params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : ''} */}
		// 			{params.value ? params.value : ''}
		// 		</Grid>
		// 	),	
		// 	valueGetter: params => {
		// 		if (params.row.submitDate) {
		// 			return format(new Date(params.row.submitDate), 'dd/MM/yyyy HH:mm')
		// 		}
		// 	}

		// },
		{
			field: 'statusID',
			headerName: 'สถานะ',
			width: 130,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => {
				var today = new Date();
				today.setDate(today.getDate() + 1);
				var dueDate = null;
				if (params.row.transactions != null && params.row.transactions.dueDate != null) {
					dueDate = new Date(params.row.transactions.dueDate);
				} else {
					dueDate = new Date();
				}
				dueDate.setHours(23, 59, 59, 999);
				return (
					<Grid
						className={`flex flex-row justify-center items-center w-32 h-7 rounded text-xs
						${params.row.statusID === 1
								? classes.waiting_assign_button
								: (params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 0)
									? classes.wait_button
									: params.row.statusID === 4
										? classes.preapprove_button
										: params.row.statusID === 5
											? classes.approve_button
											: (params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 1) || (params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 2)
												? classes.updated_button
												: params.row.statusID === 6
													? classes.reject_button
													: (params.value === 8 && (dueDate < today)) ?
														classes.reject_button
														: params.row.statusID === 10
															? classes.cancel_button
															: classes.common_button
							}
					`}>
						{
							(params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 1) ?
								'เพิ่มเอกสารแล้ว'
								: (params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 2) ?
									'ปรับปรุงข้อมูลแล้ว'
									: (params.row.statusID === 3 && params.row.requestAddtionDocStatusID === 2) ?
										'ขอปรับปรุงเอกสาร'
										: (params.row.statusID === 8 && (dueDate < today)) ?
											'เลยกำหนดชำระ'
											: params.row.statusName
						}
					</Grid>
				)
			},
			valueGetter: (params) => {
				{
					var today = new Date();
					today.setDate(today.getDate() + 1);
					var dueDate = null;
					if (params.row.transactions != null && params.row.transactions.dueDate != null) {
						dueDate = new Date(params.row.transactions.dueDate);
					} else {
						dueDate = new Date();
					}
					dueDate.setHours(23, 59, 59, 999);

					if (params.value === 2 && params.row.requestAddtionDocStatusID === 1)
						return 'เพิ่มเอกสารแล้ว';
					else if (params.value === 2 && params.row.requestAddtionDocStatusID === 2)
						return 'ปรับปรุงข้อมูลแล้ว';
					else if (params.value === 3 && params.row.requestAddtionDocStatusID === 2)
						return 'ขอปรับปรุงเอกสาร';
					else if (params.value === 8 && (dueDate < today))
						return 'เลยกำหนดชำระ';
					else
						return params.row.statusName;
				}
			},
		},
		{
			field: 'activity',
			headerName: 'กิจกรรม',
			width: 1000,
			headerAlign: 'left',
			align: 'left',
			sortable: false,
			editable: false,
			renderCell: params => {
				return (
					<Grid className="flex flex-row justify-start items-center w-full gap-x-2">
						<Button
							className={`w-24`}
							color="primary"
							variant="contained"
							onClick={() => {
								history.push(
									`/admin/sdoc/form/${params.row.id}?mountType=readOnly`
								)
							}}>
							<Menu style={{ fontSize: 16 }} className="mr-1" />
							<Grid className={`text-xs`}>รายละเอียด</Grid>
						</Button>
					</Grid>
				)
			},
		},

	]
	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarExport
					className={`w-72`}
					color="primary"
					variant="contained"
					style={{ marginRight: '10px', height: '30px' }}
					label="ดาวน์โหลดรายการใบคำร้องขอหนังสือรับรอง"
					csvOptions={{
						fileName: 'รายการใบคำร้องขอหนังสือรับรอง.csv',
						exportButtonText: 'ดาวน์โหลดรายการคำร้องขอหนังสือรับรอง',
						utf8WithBom: true,
						fields: ['runningNumber', 'brandName', 'productModel', 'operator', 'operatorInfo', 'approver', 'submitDate', 'statusID']
					}}
				/>
			</GridToolbarContainer>
		);
	}
	return (
		<>
			<Grid className="flex flex-col">
				<Grid className="py-10 px-3 sm:px-10">
					<Card className={`border-t-4 border-card_top_border`}>
						<Grid className="flex flex-col justify-center items-start overflow-auto mb-5">
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Grid>
									<ListAlt style={{ fontSize: 22 }} className="mr-1" />
								</Grid>
								<Grid className="font-bold text-sm text-card_header_color">
									รายการคำร้องขอหนังสือรับรองตนเอง
									<Tooltip title="อัพเดทข้อมูล">
										<Button
											style={{ marginLeft: 10, width: 30, height: 30 }}
											color="primary"
											variant="contained"
											onClick={() => {
												forcegetSDOCWaitStatusList();
											}}>
											<Refresh style={{ fontSize: 22 }} className="mr-1" />
										</Button>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Button
									className={`w-36`}
									color="primary"
									variant="contained"
									onClick={toggleDialog}>
									<Search style={{ fontSize: 16 }} className="mr-1" />
									<Grid className={`text-xs`}>ค้นหาขั้นสูง</Grid>
								</Button>
							</Grid>
							<Grid className="md:px-5" style={{ height: '80vh', width: '100%' }}>
								<DataGrid
									rows={rowsWaitStatus}
									columns={columnsWaitingStatus}
									pageSize={10}
									rowsPerPageOptions={[10]}
									disableSelectionOnClick
									sortModel={sortModel}
									rowHeight={66}
									onSortModelChange={(model) => {
										handleSortChange(model)
									}}
									localeText={{
										toolbarExport: 'ดาวน์โหลดข้อมูลจากตาราง',
									}}
									components={{
										NoRowsOverlay: () => {
											if (!isDataLoading) {
												return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
											} else {
												return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
											}
										},
										Toolbar: CustomToolbar,
									}}
								/>
							</Grid>
						</Grid>
					</Card>
				</Grid >
			</Grid >

			<SureModal
				open={openSubmitSDocSureModal}
				title={sureModalTitle}
				content={contentSureModal}
				onClose={() => {
					setOpenSubmitSDocSureModal(false)
				}}
				handleButton={onSubmitApproval}
				handleCancel={() => {
					setOpenSubmitSDocSureModal(false)
				}}
			/>

			<SelectAssignAdminModal
				open={openModalAssignAdmin}
				onClose={onOpenModalAssignAdmin}
				handleButton={onAssignAdmin}
				onChangeCheckerAdminID={onChangeCheckerAdminID}
				checkerAdminList={checkerAdminList}
				checkerAdminID={checkerAdminID}
			/>

			<SignSignatureModal
				open={openModalAssignSignature}
				onClose={() => setOpenModalAssignSignature(false)}
				handleButton={onAssignSignature}
				onChangeCheckerAdminID={onChangeSignature}
				checkerAdminList={signatureAdminList}
				checkerAdminID={selectedSignature}
			/>

			<Dialog
				className="w-full"
				maxWidth="md"
				fullWidth
				scroll="paper"
				open={isDialogOpen}
				onClose={toggleDialog}>
				<DialogTitle style={{ backgroundColor: '#a74345' }}>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center">
						<Grid className="flex flex-row items-center" component="div">
							<Grid className="text-white">ค้นหาขั้นสูง</Grid>
						</Grid>
						<IconButton onClick={toggleDialog} style={{ color: '#fff' }}>
							<Close />
						</IconButton>
					</Grid>
				</DialogTitle>
				<DialogContent dividers>
					<table
						className="w-full"
						style={{ textAlign: 'left', borderCollapse: 'collapse' }}
					>
						<tbody>
							<tr>
								<td style={{ width: "20%" }}>เลขที่คำขอ:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'runningNumber')?.value || ''}
										placeholder="(ไม่ต้องใส่ SR นำหน้า)"
										id="runningNumber"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ตราอักษร:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'brandName')?.value || ''}
										id="brandName"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>รุ่นของผลิตภัณฑ์:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'productModel')?.value || ''}
										id="productModel"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ผู้ยื่นเอกสาร:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'operator')?.value || ''}
										id="operator"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ยื่นในนาม:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'operatorInfo')?.value || ''}
										id="operatorInfo"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ผู้ได้รับมอบหมายงาน:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'approver')?.value || ''}
										id="approver"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>วันที่ยื่นแบบ:</td>
								<td>
									<TextField
										style={{ maxWidth: '200px', width: '200px' }}
										type="date"
										value={searchCreate}
										onChange={handleCreateChange}
									/>
								</td>
							</tr>
							<tr>
								<td>สถานะ:</td>
								<td className="w-full ">
									<Select
										style={{ maxWidth: '600px', width: '600px' }}
										value={selectedStatus}
										onChange={handleStatusChange}
										multiple
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left"
											},
											getContentAnchorEl: null
										}}
										renderValue={(selected) => {
											const selectedLabels = selected.map(value => {
												const selectedOption = statusOptions.find(option => option.value === value);
												return selectedOption ? selectedOption.label : '';
											});
											return selectedLabels.join(', ');
										}}
									>
										{statusOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												<Checkbox checked={selectedStatus.indexOf(option.value) > -1} />
												<ListItemText primary={option.label} />
												{/* {option.label} */}
											</MenuItem>
										))}
									</Select>
								</td>
							</tr>
							<tr>
								<td>บันทึกข้อมูลการค้นหา:</td>
								<td className="w-full ">
									<Switch
										checked={isSaveSearch}
										onChange={handleSaveSearch}
										color="primary"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</DialogContent>
				<DialogActions>
					<Grid
						className="w-8/12 mx-auto flex flex-row justify-between items-center"
						component="div">
						<Button
							className="w-5/12"
							onClick={onSearchResultClear}
							color="secondary"
							variant="outlined">
							ล้างผลการค้นหา
						</Button>
						<Button
							className="w-5/12"
							color="primary"
							variant="contained"
							onClick={onSearchResult}>
							ค้นหา
						</Button>
					</Grid>
				</DialogActions>
			</Dialog>

		</>
	)
}
