import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { SendOutlined } from '@material-ui/icons'
import NotifyModal from 'components/NotifyModal'
import FormSelectService from './components/FormSelectService'
import SelectOperatorModal from './components/SelectOperatorModal'
import FormRegisterCompany from './components/RegisterCompany'
import { users, operators } from 'api/index'
import { useHistory } from 'react-router-dom'

import { useStoreActions, useStoreState } from '../../../store/index'

const Identify = () => {
	const history = useHistory()
	const { servicePortalToken, userFromServicePortal } = useStoreState(s => s.userModel)
	const selectedPOA = useStoreState(s => s.poa.selected)
	const POA = useStoreState(s => s.poa.data)
	const actionIdentify = useStoreActions(a => a.userModel.identify)
	const signinSdoc = useStoreActions(a => a.sdocUserModel.signInUser)
	const { setServicePortalToken, signin: signinQos } = useStoreActions(
		a => a.userModel
	)

	const {
		setData: setPOAData,
		setDataDBD,
		setSelected: selectPOA,
	} = useStoreActions(a => a.poa)

	const signinQosWithRegisterCompany = useStoreActions(
		a => a.userModel.signinQosWithRegisterCompany
	)
	const [loadPoaSuccess, setLoadPoaSuccess] = useState(false)
	const [selectPOAError, setSelectPOAError] = useState(false)

	const [openNoPOAModal, setOpenNoPOAModal] = useState(false)
	const [openPOAModal, setOpenPOAModal] = useState(false)
	const [activeRegisterCompany, setActiveRegisterCompany] = useState(false)

	const onSelectService = service => {
		if (service === 'sdoc') {
			signinSdoc({ _token: servicePortalToken })
				.then(data => {
					if (data !== null) {
						history.replace('/sdoc')
					}
				})
				.catch(() => {
					alert('error case 1')
				})
		} else if (service === 'qos') {
			if (POA) {
				// if (
				// 	typeof userFromServicePortal.UserPOA === 'object' &&
				// 	typeof userFromServicePortal.UserPOA.length === 'undefined'
				// ) {
				// 	const newListPOA = [userFromServicePortal.UserPOA]
				// 	// setPOAData(newListPOA)
				// } else {
				// 	setPOAData(userFromServicePortal.UserPOA)
				// }
				setOpenPOAModal(true)
			} else {
				setOpenNoPOAModal(true)
			}
		}
	}
	const submitRegisterCompany = async payload => {
		await signinQosWithRegisterCompany(payload)
			.then(e => {
				history.replace('/')
			})
			.catch(e => {
				console.log(e)
			})
	}
	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search)
		const params = Object.fromEntries(urlSearchParams.entries())
		async function getListPOA(token) {
			const res = await users.getMyPOA(token)
			if (res) {
				setPOAData(res)
				setLoadPoaSuccess(true)
			}
		}

		if (!params._token) {
			// history.replace('/sign-in')
		}
		setServicePortalToken(params._token)

		actionIdentify({ _token: params._token })
			.then(data => { })
			.catch(() => {
				setOpenNoPOAModal(true)
			})

		getListPOA(params._token)

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (selectedPOA !== null && selectedPOA.operator !== null) {
			signinQos({ _token: servicePortalToken, poa: selectedPOA.juristicNo })
				.then(data => {
					if (data !== null) {
						history.replace('/')
					}
				})
				.catch(() => {
					alert('error case 1')
				})
		} else if (selectedPOA !== null && selectedPOA.operator === null) {
			operators
				.getOperatorInfoDBD({ taxno: selectedPOA.juristicNo, pid: userFromServicePortal.UserProfile.NationalID })
				.then(e => {
					if (e.status !== 200) {
						setSelectPOAError(true)
						return false
					} else {
						return e
					}
				})
				.then(e => e.data)
				.then(data => {
					if (data?.data1000101001) {
						const foundCompany = data.data1000101001.ResultList[0]
						setDataDBD(foundCompany)
						setOpenPOAModal(false)
						setActiveRegisterCompany(true)
					}
				})
				.catch(err => {
					console.error(err)
				})
		}
		// eslint-disable-next-line
	}, [selectedPOA])

	return (
		<Grid className="h-screen flex flex-col items-center justify-center sign-in-container p-3">
			{loadPoaSuccess && !activeRegisterCompany && (
				<FormSelectService onSelectService={onSelectService} />
			)}
			{activeRegisterCompany && (
				<FormRegisterCompany
					onSubmit={submitRegisterCompany}
					goBack={() => {
						setActiveRegisterCompany(false)
					}}
				/>
			)}
			<NotifyModal
				title="โปรดทราบ"
				content="คุณไม่ได้รับสิทธิ์การเข้าใช้งานระบบ QoS"
				// content="ขณะนี้ระบบทำการปิดปรับปรุง โปรดลองใหม่อีกครั้งในภายหลัง เวลา 20.00 น."
				header_icon={
					<SendOutlined style={{ fontSize: 20 }} className="mr-1 text-white" />
				}
				open={openNoPOAModal}
				onClose={() => {
					setOpenNoPOAModal(false)
					history.replace('/signin')
				}}
			/>

			<SelectOperatorModal
				title="กรุณาเลือก"
				content="คุณยังไม่ได้รับสิทธ์การมอำนาจจากบริษัท กรณีข้อมูลผิดพลาด กรุณาติดต่อเจ้าหน้าที่ 02-xxx-xxxx"
				header_icon={
					<SendOutlined style={{ fontSize: 20 }} className="mr-1 text-white" />
				}
				isError={selectPOAError}
				open={openPOAModal}
				onClose={() => {
					selectPOA(null)
					setOpenPOAModal(false)
					setSelectPOAError(false)
				}}
			/>
		</Grid>
	)
}

export default Identify
