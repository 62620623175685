import React, { useState, useEffect, useMemo, useCallback } from 'react'

import {
	Grid,
	Card,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Select,
	MenuItem,
	IconButton,
	Checkbox,
	ListItemText,
	Switch,
	FormControlLabel,
} from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import {
	ListAlt,
	Description,
	NoteAdd,
	Cancel,
	PlaylistAddCheck,
	Menu,
	CheckCircle,
	SyncAlt,
	Search,
	Close,
	ArrowBack,
} from '@material-ui/icons'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import SelectAssignAdminModal from 'components/sdoc-admin/manage/select-assign-admin-modal/index'
import SignSignatureModal from 'components/sdoc-admin/manage/sign-signature-modal/index'
import SureModal from 'components/SureModal'
import { useStoreState } from '../../../../store/index'
import { useStoreRehydrated } from '@store'

import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { format, set } from 'date-fns'


import { sdoc, sdocAdmin, admin, adminQoS } from 'api/index'
import { CheckBox } from '../../../../../node_modules/@material-ui/icons/index'
// import { Switch } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles(() => {
	return {
		details_button: {
			backgroundColor: '#466AE9',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#466AE9',
			},
		},
		common_button: {
			backgroundColor: '#eee',
			color: '#000',
			'&:hover': {
				backgroundColor: '#eee',
			},
		},
		approve_button: {
			backgroundColor: '#1DBF73',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#1DBF73',
			},
		},
		wait_button: {
			backgroundColor: '#F6B50B',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#F6B50B',
			},
		},
		reject_button: {
			backgroundColor: '#DB4D44',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#DB4D44',
			},
		},
		updated_button: {
			backgroundColor: '#466AE9',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#466AE9',
			},
		},
		cancel_button: {
			backgroundColor: '#DB4D44',
			color: '#fff',
			'&:hover': {
				backgroundColor: '#DB4D44',
			},
		},
		preapprove_button: {
			backgroundColor: '#46E9A4',
			color: '#000',
			'&:hover': {
				backgroundColor: '#46E9A4',
			},
		},
		waiting_assign_button: {
			backgroundColor: '#FAFAD2',
			color: '#000',
			'&:hover': {
				backgroundColor: '#FAFAD2',
			},
		},

	}
})

// Page Description: สถานะการยื่นแบบหนังสือรับรองตนเอง
export default function SDOCStatus() {
	const isRehydrated = useStoreRehydrated()
	const history = useHistory()
	const classes = useStyles()
	const myInfo = useStoreState(
		s => s.sdocAdminModel.adminMESDOC
	)

	const [rowsWaitStatus, setRowsWaitStatus] = useState([])
	const [isDataLoading, setIsDataLoading] = useState(false)
	const [RowsData, setRowsData] = useState([])
	const [myRole, setMyRole] = useState(null)
	const [myID, setMyID] = useState(null)
	const [checkerAdminList, setCheckerAdminList] = useState([])
	const [signatureAdminList, setSignatureAdminList] = useState([])
	const [sDocID, setSDocID] = useState(null)
	const [checkerAdminID, setCheckerAdminID] = useState('')
	const [selectedSignature, setSelectedSignature] = useState(null)

	// Sure Modal
	const [openSubmitSDocSureModal, setOpenSubmitSDocSureModal] = useState(false)
	const [submitType, setSubmitType] = useState('approve')
	const [remark, setRemark] = useState('')
	const [currentActionSDoc, setCurrentActionSDoc] = useState('')
	const [sortModel, setSortModel] = useState([
		{
			field: 'runningNumber',
			sort: 'desc',
		},
	]);

	const [openModalAssignAdmin, setOpenModalAssignAdmin] = useState(false)
	const [openModalAssignSignature, setOpenModalAssignSignature] =
		useState(false)

	const sureModalTitle = useMemo(
		() => {
			if (submitType === 'approve') {
				return 'อนุมัติแบบหนังสือรับรองตนเอง';
			} else if (submitType === 'reject') {
				return 'ไม่อนุมัติแบบหนังสือรับรองตนเอง';
			} else if (submitType === 'requestAdditionDoc') {
				return 'ขอเอกสารเพิ่มเติม';
			} else if (submitType === 'requestModifyInfo') {
				return 'ขอปรับปรุงข้อมูล';
			} else if (submitType === 'rejectchecker') {
				return 'ไม่อนุมัติออกหนังสือรับรองตนเองและส่งกลับไปยังผู้ตรวจสอบ';
			} else if (submitType === 'updateCustomerInfo') {
				return 'อัพเดทข้อมูลลูกค้า DataCenter / SAP ';
			}
		},
		[submitType]
	)

	// filter Dialog / Modal
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [searchCreate, setSearchCreate] = useState('');
	const [searchLastUpdated, setSearchLastUpdated] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const [isSaveSearch, setIsSaveSearch] = useState(true);

	const statusOptions = [
		// { value: '0', label: 'ทั้งหมด' },
		{ value: '1', label: 'รอมอบหมาย' },
		{ value: '2', label: 'รอตรวจสอบ/เพิ่มเอกสารแล้ว/ปรับปรุงข้อมูลแล้ว' },
		{ value: '3', label: 'ขอเอกสารเพิ่ม/ขอปรับปรุงเอกสาร' },
		{ value: '4', label: 'รอออกใบรับรอง' },
		{ value: '5', label: 'อนุมัติ' },
		{ value: '6', label: 'ไม่อนุมัติ' },
		{ value: '8', label: 'รอชำระเงิน' },
		{ value: '82', label: 'เลยกำหนดชำระ' },
		{ value: '9', label: 'รอออกใบเสร็จรับเงิน' },
		{ value: '10', label: 'ยกเลิกคำขอ' },
		{ value: '11', label: 'เลยระยะเวลา' },
	];
	const toggleDialog = () => {
		setIsDialogOpen(!isDialogOpen);
	};
	const handleSearchChange = (event) => {
		const { id, value } = event.target;
		setSearchQuery((prevSearchQuery) => {
			const updatedQuery = [...prevSearchQuery];
			const existingItemIndex = updatedQuery.findIndex((item) => item.id === id);
			if (existingItemIndex !== -1) {
				updatedQuery[existingItemIndex] = { id, value };
			} else {
				updatedQuery.push({ id, value });
			}
			return updatedQuery;
		});
		// console.log(searchQuery);
	};

	const handleStatusChange = (event) => {
		setSelectedStatus(event.target.value);
	};
	const handleCreateChange = (event) => {
		setSearchCreate(event.target.value);
	};
	const handleLastUpdatedChange = (event) => {
		setSearchLastUpdated(event.target.value);
	};
	const handleSaveSearch = () => {
		setIsSaveSearch(!isSaveSearch);
	};

	const contentSureModal = useMemo(
		() => {

			if (submitType === 'approve') {
				return <div className="text-center">ยืนยันอนุมัติแบบหนังสือรับรองตนเอง</div>
			} else if (submitType === 'reject') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="text"
					label="เหตุผลที่ไม่อนุมัติ"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'requestAdditionDoc') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="textarea"
					label="เอกสารที่ขอเพิ่มเติม"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'requestModifyInfo') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="textarea"
					label="ขอปรับปรุงข้อมูล"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'rejectchecker') {
				return <TextField
					fullWidth
					multiline
					rows={3}
					type="text"
					label="เหตุผลที่ไม่อนุมัติ และส่งกลับไปยังผู้ตรวจสอบ"
					autoFocus={true}
					variant="filled"
					size="small"
					onChange={e => {
						setRemark(e.target.value)
					}}
					value={remark}
				/>
			} else if (submitType === 'updateCustomerInfo') {
				return <Grid>
					ปรับปรุงข้อมูลลูกค้า เพื่อให้ข้อมูลลูกค้าใน DataCenter / SAP ตรงกับในระบบ
				</Grid>
			} else if (submitType === 'resentBill') {
				return <Grid>
					ส่งใบนำฝากเงินใหม่ (กรณีส่งล้มเหลว)
				</Grid>
			} else if (submitType === 'BackToPreapprove') {
				return <Grid>
					ย้อนสถานะกลับไปเป็นรอตรวจสอบ
				</Grid>
			}
		},
		[submitType, remark]
	)

	const onOpenModalAssignAdmin = () => {

		setOpenModalAssignAdmin(!openModalAssignAdmin)
	}

	const handleIssueBtn = e => {
		setSDocID(e)
		adminQoS.getSignature().then(e => {
			setSignatureAdminList(e)

			setOpenModalAssignSignature(true)
		})
	}

	const onClickAssignAdmin = sDocID => {
		setSDocID(sDocID)
		setCheckerAdminID('')
		onOpenModalAssignAdmin()
	}

	const onChangeCheckerAdminID = event => {
		setCheckerAdminID(event.target.value)
	}

	const onChangeSignature = event => {
		setSelectedSignature(event.target.value)
	}

	const createWaitingRow = (dataSDocList, i) => {
		const rowData = {
			id: dataSDocList[i].id,
			brandName: dataSDocList[i].brandName,
			operator:
				(dataSDocList[i].sDocUser.prefix ?? '') +
				dataSDocList[i].sDocUser.firstname +
				' ' +
				dataSDocList[i].sDocUser.lastname,
			submitDate: dataSDocList[i].createDate,
			statusID: dataSDocList[i].sDocStatusID,
			// statusID: (i % 6) + 1,
			statusName: dataSDocList[i].sDocStatus.statusName,
			approver: dataSDocList[i].approver
				? dataSDocList[i].approver.firstname +
				' ' +
				dataSDocList[i].approver.lastname
				: 'ยังไม่ได้มอบหมาย',
			productModel: dataSDocList[i].productModel,
			runningNumber: dataSDocList[i].runningNo,
			requestAddtionDocStatusID: dataSDocList[i].requestAddtionDocStatusID,
			operatorInfo: dataSDocList[i].senderType === 'organization' ? dataSDocList[i].operatorTitle : null,
			transactions: dataSDocList[i].sDocTransactions,
			lastUpdate: dataSDocList[i].sDocLogs[0]?.createDate,
			IsUploadedAttachment: dataSDocList[i].isUploadedAttachment,
		}

		return rowData
	}

	const getSDocStatusList = async () => {
		// Get Status List
		const resStatusList = await sdoc.getListsStatus()
		const dataStatusList = resStatusList.data
		const statusListTemp = []
		if (resStatusList) {
			if (resStatusList.status === 200) {
				for (let i = 0; i < dataStatusList.length; i++) {
					statusListTemp.push({
						id: dataStatusList[i].id,
						statusName: dataStatusList[i].statusName,
					})
				}
			}
		}
		return statusListTemp
	}

	const getCheckerAdminList = async () => {
		const resAdminList = await sdocAdmin.getAdminList()

		const adminListTemp = []
		if (resAdminList) {
			if (resAdminList.status === 200) {
				const dataAdminList = resAdminList.data
				for (let i = 0; i < dataAdminList.length; i++) {
					const adminAccess = dataAdminList[i].adminAccess[0] ?? null
					if (
						adminAccess.dataportalServiceID === 2 &&
						// (adminAccess.adminRoleID === 11 || adminAccess.adminRoleID === 15) // show exclusive admin
						( adminAccess.adminRoleID === 11 ) // not show exclusive admin
					) {
						adminListTemp.push({
							id: dataAdminList[i].id,
							name:
								dataAdminList[i].firstname + ' ' + dataAdminList[i].lastname,
						})
					}
				}

				setCheckerAdminList(adminListTemp)
			}
		}
	}

	const [isShowFinished, setIsShowFinished] = useState(false)
	const handleSwitch = () => {
		if (isDataLoading) return false
		if (isShowFinished == false) {
			// show popup that will tell user that when enable this server will load a large amount of data
			Swal.fire({
				title: 'คุณต้องการแสดงรายการที่เสร็จสิ้นแล้วใช่หรือไม่',
				text: 'การแสดงรายการที่เสร็จสิ้นแล้วอาจใช้เวลาในการเรียกข้อมูลนาน',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'ใช่',
				cancelButtonText: 'ไม่ใช่',
				reverseButtons: true,
			}).then(result => {
				if (result.isConfirmed) {
					setIsShowFinished(!isShowFinished)
				}
			}
			)
		} else {
			setIsShowFinished(!isShowFinished)
		}
	}
	const getSDOCWaitStatusList = useCallback(async () => {
		// Get Status List
		setIsDataLoading(true)
		getSDocStatusList()

		await admin.me().then(async event => {
			if (event) {
				const dataMe = event.data
				setMyRole(dataMe.roleID)
				setMyID(dataMe.id)

				// Get SDoc List
				const showFinished = isShowFinished
				const resList = await sdoc.getLists(showFinished)
				const dataSDocList = resList.data
				// set expired status 
				dataSDocList.forEach((item) => {
					if (item.sDocStatusID === 8) {
						var today = new Date();
						today.setDate(today.getDate() + 1);
						var dueDate = null;

						if (item.sDocTransactions != null && item.sDocTransactions.dueDate != null) {
							dueDate = new Date(item.sDocTransactions.dueDate);
						} else {
							dueDate = new Date();
						}
						dueDate.setHours(23, 59, 59, 999);
						// console.log(dueDate);
						if (dueDate < today) {
							item.sDocStatusID = 82;
						}
					}
				});


				if (resList) {
					if (resList.status === 200) {
						const rowsTemp = []

						// Role === 'ผู้ตรวจสอบข้อมูล'
						if (dataMe.roleID === 11) {
							for (let i = 0; i < dataSDocList.length; i++) {
								if (
									// Show only own approver && status !== 'รอมอบหมาย'
									// Not show status === 'สำเร็จ'

									dataMe.id === dataSDocList[i].approverID &&
									dataSDocList[i].sDocStatusID !== 1
								) {
									if (dataSDocList[i].sDocStatusID !== 5)
										rowsTemp.push(createWaitingRow(dataSDocList, i))
									// Not show status === 'สำเร็จ'
									// <--- Add Data to Array
								}
							}
						}

						// Role === 'ผู้ออกใบรับรอง'
						else if (dataMe.roleID === 9) {
							for (let i = 0; i < dataSDocList.length; i++) {
								if (
									// Show only status === 'รอออกใบรับรอง' && status === 'ยกเลิก'
									// Not show status === 'สำเร็จ'
									dataSDocList[i].sDocStatusID === 4
									// || dataSDocList[i].sDocStatusID === 6
								) {
									rowsTemp.push(createWaitingRow(dataSDocList, i))
									// Not show status === 'สำเร็จ'
									// ---> Add Data to Array
								}
							}
						}

						// Role === 'ผู้มอบหมายงาน'
						else if (dataMe.roleID === 10) {
							for (let i = 0; i < dataSDocList.length; i++) {
								if (dataSDocList[i].sDocStatusID !== 5)
									rowsTemp.push(createWaitingRow(dataSDocList, i))
								// ---> Add Data to Array
								// Not show status === 'สำเร็จ'
							}
						}// Role === 'ผุ้มีอำนาจสูงสุด'
						else if (dataMe.roleID === 15) {
							for (let i = 0; i < dataSDocList.length; i++) {

								rowsTemp.push(createWaitingRow(dataSDocList, i))
								// ---> Add Data to Array
								// Not show status === 'สำเร็จ'
							}
						}
						setRowsData(rowsTemp)
						setRowsWaitStatus(rowsTemp)
					}
				}
			}

		})
		getCheckerAdminList()
		setIsDataLoading(false)
	}, [isShowFinished, isSearching])
	// filter 
	const onSearchResult = () => {
		let searchedData = RowsData;
		const savedFilters = {
			searchQuery: searchQuery,
			searchCreate: searchCreate,
			searchLastUpdated: searchLastUpdated,
			selectedStatus: selectedStatus
		};
	
		// Save filters to localStorage if isSaveSearch is true
		if (isSaveSearch){
			localStorage.setItem('savedFilters', JSON.stringify(savedFilters));
		}else{
			localStorage.removeItem('savedFilters');
		}
		if(searchQuery)
		// console.log("searchQuery >> ", searchQuery)
		searchQuery.forEach((query) => {
			const { id, value } = query;
			if (value !== '' && value !== null && value !== undefined && value.length > 0) {
				// console.log("id >> ", id, " value >> ", value)
				searchedData = searchedData.filter((item) => {
					const itemValue = item[id];
					// console.log("itemValue >> ", itemValue);
					// console.log("typeof itemValue >> ", typeof itemValue);
					if (typeof itemValue === 'string') {
						return itemValue.toLowerCase().includes(value.toLowerCase());
					} else if (typeof itemValue === 'object') {
						return false;
					} else {
						return itemValue.toString().includes(value);
					}
				});
			}
		});
	
		if (searchCreate !== '') {
			searchedData = searchedData.filter((item) => {
				const itemDate = new Date(item.submitDate.split('T')[0]);
				const searchDate = new Date(searchCreate);
				return itemDate.getTime() === searchDate.getTime();
			});
		}
		if (searchLastUpdated !== '') {
			searchedData = searchedData.filter((item) => {
				const itemDate = new Date(item.lastUpdate.split('T')[0]);
				const searchDate = new Date(searchLastUpdated);
				return itemDate.getTime() === searchDate.getTime();
			});
		}
		if (selectedStatus.length > 0 && selectedStatus[0] !== '0') {
			searchedData = searchedData.filter(
				(item) => selectedStatus.includes(item.statusID.toString())
			);
		}
	
		const mergedSearched = searchedData;
		setRowsWaitStatus(mergedSearched);
		setIsDialogOpen(false);
		setIsSearching(true);
		return;
	};
	
	// Function to apply saved filters on component mount
	const applySavedFilters = () => {
			const savedFilters = localStorage.getItem('savedFilters');

		if (savedFilters) {
			const { searchQuery, searchCreate, searchLastUpdated, selectedStatus } = JSON.parse(savedFilters);
			// Apply the saved filters to your state variables
			setSearchQuery(searchQuery);
			setSearchCreate(searchCreate);
			setSearchLastUpdated(searchLastUpdated);
			setSelectedStatus(selectedStatus);
			setIsSearching(true);
			setIsSaveSearch(true);
		}
	};
	
	// Call applySavedFilters when the component mounts
	useEffect(() => {
		applySavedFilters();
	}, [isSearching]);
	useEffect(() => {
        if (RowsData && isSearching) {
            onSearchResult();
        }
    }, [RowsData, isSearching]); // Call onSearchResult when rowsData or isSearching changes

	

	const onSearchResultClear = () => {
		setSearchQuery([]);
		setSearchCreate('');
		setSearchLastUpdated('');
		setSelectedStatus([]);
		setRowsWaitStatus(RowsData);
		setIsSearching(false);
		setIsSaveSearch(false);
		
		// Remove the saved filters from localStorage
		localStorage.removeItem('savedFilters');
		
		return;
	};
	

	const onDeny = useCallback(
		async sDocID => {
			const res = await sdocAdmin.denySDoc(sDocID, remark)
			if (res && res.status === 200) {
				getSDOCWaitStatusList()
				setOpenSubmitSDocSureModal(false)
				setRemark('');
			}
		},
		[getSDOCWaitStatusList, remark]
	)

	const sendToIssue = useCallback(
		async sDocID => {
			const res = await sdocAdmin.sendToIssue(sDocID)
			if (res && res.status === 200) {
				getSDOCWaitStatusList()
				setOpenSubmitSDocSureModal(false)
			}
		},
		[getSDOCWaitStatusList]
	)

	const onSubmitApproval = useCallback(() => {
		if (submitType === 'approve') {
			sendToIssue(currentActionSDoc)
		} else if (submitType === 'reject') {
			onDeny(currentActionSDoc)
		} else if (submitType === 'requestAdditionDoc') {
			onRequestAddition(currentActionSDoc);
		} else if (submitType === 'requestModifyInfo') {
			onRequestModifyInfo(currentActionSDoc);
		} else if (submitType === 'rejectchecker') {
			onDenyChecker(currentActionSDoc)
		} else if (submitType === 'updateCustomerInfo') {
			onUpdateCustomerInfo(currentActionSDoc)
		} else if (submitType === 'resentBill') {
			onResentBill(currentActionSDoc)
		} else if (submitType === 'BackToPreapprove') {
			onBackToPreapprove(currentActionSDoc)
		}
	}, [onDeny, sendToIssue, currentActionSDoc, submitType])

	const onAssignAdmin = async () => {
		const res = await sdocAdmin.assignAdmin(sDocID, checkerAdminID)
		if (res && res.status === 200) {
			Swal.fire({
				title: 'มอบหมายงานสำเร็จ',
				text: '',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
			setCheckerAdminID('')
			onOpenModalAssignAdmin()
			getSDOCWaitStatusList()
		}
	}
	const onAssignSignature = async () => {
		const res = await sdocAdmin.approveSDoc(sDocID, myInfo.id)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
			setOpenModalAssignSignature(false)
			setRemark('');
		}
	}

	const onRequestAddition = async sDocID => {
		const res = await sdocAdmin.requestAddition(sDocID, remark)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
			setOpenSubmitSDocSureModal(false);
			setRemark('');
		}
	}
	const onRequestModifyInfo = async sDocID => {
		const res = await sdocAdmin.requestModifyInfo(sDocID, remark)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
			setOpenSubmitSDocSureModal(false);
			setRemark('');
		}
	}

	const onDenyChecker = useCallback(
		async sDocID => {
			const res = await sdocAdmin.denyCheckerSDoc(sDocID, remark)
			if (res && res.status === 200) {
				getSDOCWaitStatusList()
				setOpenSubmitSDocSureModal(false)
				setRemark('');
			}
		},
		[getSDOCWaitStatusList, remark]
	)

	const onRejectJob = async sDocID => {
		const res = await sdocAdmin.rejectJob(sDocID)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
		}
	}
	const onUpdateCustomerInfo = async sDocID => {
		const res = await sdocAdmin.updateCustomerInfo(sDocID)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
			setOpenSubmitSDocSureModal(false)
			Swal.fire({
				title: 'อัพเดทข้อมูลลูกค้าสำเร็จ',
				text: 'อัพเดทข้อมูลลูกค้าสำเร็จไปยัง DataCenter / SAP สำเร็จ',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
		} else {
			setOpenSubmitSDocSureModal(false)
			Swal.fire({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถอัพเดทข้อมูลลูกค้าไปยัง DataCenter / SAP ได้',
				icon: 'error',
				confirmButtonText: 'ปิด'
			})
		}
	}

	const onResentBill = async sDocID => {
		const res = await sdocAdmin.billResent(sDocID)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
			setOpenSubmitSDocSureModal(false)
			Swal.fire({
				title: 'ส่งข้อมูลใบนำฝากชำระเงินสำเร็จ',
				text: 'ส่งข้อมูลใบนำฝากชำระเงินไปยัง DataCenter / SAP สำเร็จ',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
		} else {
			setOpenSubmitSDocSureModal(false)
			Swal.fire({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถส่งข้อมูลใบนำฝากชำระเงินไปยัง DataCenter / SAP ได้',
				icon: 'error',
				confirmButtonText: 'ปิด'
			})
		}
	}

	const onBackToPreapprove = async sDocID => {
		const res = await sdocAdmin.backToPreapprove(sDocID)
		if (res && res.status === 200) {
			getSDOCWaitStatusList()
			setOpenSubmitSDocSureModal(false)
			Swal.fire({
				title: 'ย้อนกลับไปยังก่อนการอนุมัติ',
				text: 'ย้อนกลับไปยังก่อนการอนุมัติสำเร็จ',
				icon: 'success',
				confirmButtonText: 'ปิด'
			})
		} else {
			setOpenSubmitSDocSureModal(false)
			Swal.fire({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถย้อนกลับไปยังก่อนการอนุมัติได้',
				icon: 'error',
				confirmButtonText: 'ปิด'
			})
		}
	}

	const handleSortChange = (model) => {
		if (isRehydrated && model[0] !== sortModel[0])
			setSortModel(model);
	};
	useEffect(() => {
		if (isRehydrated) {
			getSDOCWaitStatusList()
		}
		//eslint-disable-next-line
	}, [isRehydrated])
	useEffect(() => {
		setRowsWaitStatus([])
		getSDOCWaitStatusList()
	}, [isShowFinished])

	var columnsWaitingStatus = [
		{
			field: 'runningNumber',
			headerName: 'เลขที่คำขอ',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'brandName',
			headerName: 'ตราอักษร (Brand Name)',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'productModel',
			headerName: 'รุ่นของผลิตภัณฑ์',
			width: 180,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'operator',
			headerName: 'ผู้ยื่นเอกสาร',
			width: 200,
			headerAlign: 'left',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params) => (
				<Grid style={{ lineHeight: "normal", whiteSpace: "normal" }}>
					{/* {params.row.operator}<br />{params.row.operatorInfo && ('แทน ' + params.row.operatorInfo)} */}
					{params.row.operator}
				</Grid>
			),
			// valueGetter: (params) => {
			// 	if (params.row.operatorInfo) {
			// 		return params.value + ' แทน ' + params.row.operatorInfo + ')'
			// 	}
			// },
		},
		{
			field: 'operatorInfo',
			headerName: 'ยื่นในนาม',
			width: 200,
			headerAlign: 'left',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params) => (
				<Grid style={{ lineHeight: "normal", whiteSpace: "normal" }}>
					{params.row.operatorInfo && (params.row.operatorInfo)}
				</Grid>
			),
		},
		{
			field: 'approver',
			headerName: 'ผู้ได้รับมอบหมายงาน',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'refnO1',
			headerName: 'หมายเลขอ้างอิง 1',
			width: 220,
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			editable: false,
			hide: true,
			renderCell: (params) => (
				<Grid style={{ lineHeight: "normal", whiteSpace: "normal", textAlign: "center" }}>
					{params.row.transactions ? params.row.transactions.refnO1 : ''}

				</Grid>
			),
		},
		{
			field: 'refnO2',
			headerName: 'หมายเลขอ้างอิง 2',
			width: 220,
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			hide: true,
			renderCell: (params) => (
				<Grid style={{ lineHeight: "normal", whiteSpace: "normal" }}>
					{params.row.transactions ? params.row.transactions.refnO2 : ''}
				</Grid>
			),
		},
		{
			field: 'submitDate',
			headerName: 'วันที่ยื่นแบบ',
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => (
				<Grid className="">
					{/* {params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : ''} */}
					{params.value ? params.value : ''}
				</Grid>
			),
			valueGetter: params => {
				if (params.row.submitDate) {
					return format(new Date(params.value), 'dd/MM/yyyy HH:mm')
				}
			}

		},
		{
			field: 'lastUpdate',
			headerName: 'วันที่อัพเดทล่าสุด',
			width: 200,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => (
				<Grid className="">
					{params.row.lastUpdate ? format(new Date(params.row.lastUpdate), 'dd/MM/yyyy HH:mm') : ''}
				</Grid>
			),
			filterValueGetter: params => {
				if (params.row.lastUpdate) {
					return format(new Date(params.row.lastUpdate), 'dd/MM/yyyy HH:mm');
				}
			},
		},
		{
			field: 'statusID',
			headerName: 'สถานะ',
			width: 130,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			editable: false,
			renderCell: params => {
				var today = new Date();
				today.setDate(today.getDate() + 1);
				var dueDate = null;
				if (params.row.transactions != null && params.row.transactions.dueDate != null) {
					dueDate = new Date(params.row.transactions.dueDate);
				} else {
					dueDate = new Date();
				}
				dueDate.setHours(23, 59, 59, 999);
				return (
					<Grid
						className={`flex flex-row justify-center items-center w-32 h-7 rounded text-xs
						${params.row.statusID === 1
								? classes.waiting_assign_button
								: (params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 0)
									? classes.wait_button
									: params.row.statusID === 4
										? classes.preapprove_button
										: params.row.statusID === 5
											? classes.approve_button
											: (params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 1) || (params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 2)
												? classes.updated_button
												: params.row.statusID === 6
													? classes.reject_button
													: (params.value === 82 && (dueDate < today)) ?
														classes.reject_button
														: params.row.statusID === 10
															? classes.cancel_button
															: classes.common_button
							}
					`}>
						{
							(params.row.statusID === 1 && params.row.IsUploadedAttachment === false) ?
								'คำขอไม่สมบูรณ์'
								: (params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 1) ?
									'เพิ่มเอกสารแล้ว'
									: (params.row.statusID === 2 && params.row.requestAddtionDocStatusID === 2) ?
										'ปรับปรุงข้อมูลแล้ว'
										: (params.row.statusID === 3 && params.row.requestAddtionDocStatusID === 2) ?
											'ขอปรับปรุงเอกสาร'
											: (params.row.statusID === 82 && (dueDate < today)) ?
												'เลยกำหนดชำระ'
												: params.row.statusName
						}
					</Grid>
				)
			},
			valueGetter: (params) => {
				{
					var today = new Date();
					today.setDate(today.getDate() + 1);
					var dueDate = null;
					if (params.row.transactions != null && params.row.transactions.dueDate != null) {
						dueDate = new Date(params.row.transactions.dueDate);
					} else {
						dueDate = new Date();
					}
					dueDate.setHours(23, 59, 59, 999);
					if (params.row.statusID === 1 && params.row.IsUploadedAttachment === false)
						return 'คำขอไม่สมบูรณ์';
					else if (params.value === 2 && params.row.requestAddtionDocStatusID === 1)
						return 'เพิ่มเอกสารแล้ว';
					else if (params.value === 2 && params.row.requestAddtionDocStatusID === 2)
						return 'ปรับปรุงข้อมูลแล้ว';
					else if (params.value === 3 && params.row.requestAddtionDocStatusID === 2)
						return 'ขอปรับปรุงเอกสาร';
					else if (params.value === 82 && (dueDate < today))
						return 'เลยกำหนดชำระ';
					else
						return params.row.statusName;
				}
			},
		},
		{
			field: 'activity',
			headerName: 'กิจกรรม',
			width: 1500,
			headerAlign: 'left',
			align: 'left',
			sortable: false,
			editable: false,
			renderCell: params => {
				return (
					<Grid className="flex flex-row justify-start items-center w-full gap-x-2">
						<Button
							className={`w-24`}
							color="primary"
							variant="contained"
							onClick={() => {
								history.push(
									`/admin/sdoc/form/${params.row.id}?mountType=readOnly&ApprovalControl=true`
								)
							}}>
							<Menu style={{ fontSize: 16 }} className="mr-1" />
							<Grid className={`text-xs`}>รายละเอียด</Grid>
						</Button>

						{(myRole === 10) && (
							/* ผู้มอบหมาย */
							(params.row.statusID === 1 && params.row.IsUploadedAttachment == true) ? (
								<Button
									className={`w-40`}
									color="primary"
									variant="contained"
									onClick={() => {
										onClickAssignAdmin(params.row.id)
									}}>
									<PlaylistAddCheck style={{ fontSize: 16 }} className="mr-1" />
									<Grid className={`text-xs`}>เลือกผู้ตรวจสอบข้อมูล</Grid>
								</Button>
							) : params.row.statusID === 5 ? (
								<>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocPaper/${params.row.id}`}
										target="_blank"
										rel="noreferrer">
										<Button
											className={`w-40`}
											color="primary"
											variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดหนังสือรับรอง</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${params.row.id}/AdminGetInvoice`}
										target="_blank"
										rel="noreferrer">
										<Button className={`w-40`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบเสร็จรับเงิน</Grid>
										</Button>
									</a>
								</>
							) : (
								<Grid></Grid>
							)
						)}

						{(myRole === 11) && (
							/* ผู้ตรวจข้อมูล */
							params.row.statusID === 2 ||
								params.row.statusID === 3 ||
								params.row.statusID === 5 ? (
								// Check if have other Button except Detail Button
								params.row.statusID === 3 ? (
									// Status 3 have Cancel Button
									<>
										{!(params.row.sDocTransactions && params.row.sDocTransactions?.invoiceNo !== null) &&
											<Button
												className={`w-24`}
												variant="contained"
												color="primary"
												onClick={() => {
													setCurrentActionSDoc(params.row.id)
													setSubmitType('reject')
													setOpenSubmitSDocSureModal(true)
												}}>
												<Cancel style={{ fontSize: 16 }} className="mr-1" />
												<Grid className={`text-xs`}>ไม่อนุมัติ</Grid>
											</Button>
										}
									</>
								) : (
									<Grid className="flex flex-row justify-start items-center gap-x-2">
										<Button
											className={`w-24`}
											color="primary"
											variant="contained"
											onClick={() => {
												// onRequestAddition(params.row.id)
												setCurrentActionSDoc(params.row.id)
												setSubmitType('requestAdditionDoc')
												setOpenSubmitSDocSureModal(true)
											}}>
											<NoteAdd style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ขอเอกสารเพิ่ม</Grid>
										</Button>

										<Button
											className={`w-30`}
											color="primary"
											variant="contained"
											onClick={() => {
												setCurrentActionSDoc(params.row.id)
												setSubmitType('requestModifyInfo')
												setOpenSubmitSDocSureModal(true)
											}}>
											<NoteAdd style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ขอปรับปรุงข้อมูล</Grid>
										</Button>

										<Button
											className={`w-24 ${classes.approve_button}`}
											variant="contained"
											onClick={() => {
												setCurrentActionSDoc(params.row.id)
												setSubmitType('approve')
												setOpenSubmitSDocSureModal(true)
											}}>
											<CheckCircle style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>อนุมัติ</Grid>
										</Button>
										{!(params.row.sDocTransactions && params.row.sDocTransactions?.invoiceNo !== null) &&
											<Button
												className={`w-24`}
												variant="contained"
												color="primary"
												onClick={() => {
													setCurrentActionSDoc(params.row.id)
													setSubmitType('reject')
													setOpenSubmitSDocSureModal(true)
												}}>
												<Cancel style={{ fontSize: 16 }} className="mr-1" />
												<Grid className={`text-xs`}>ไม่อนุมัติ</Grid>
											</Button>
										}

										<Button
											className={`w-20`}
											variant="contained"
											color="primary"
											onClick={() => {
												onRejectJob(params.row.id)
											}}>
											<Cancel style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ปฏิเสธงาน</Grid>
										</Button>

										<a
											href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/SDocPreview/${params.row.id}/${myID}`}
											target="_blank"
											rel="noreferrer">
											<Button
												className={`w-40`}
												color="primary"
												variant="contained">
												<Description style={{ fontSize: 16 }} className="mr-1" />
												<Grid className={`text-xs`}>ตัวอย่างหนังสือรับรอง</Grid>
											</Button>
										</a>
									</Grid>
								)
							) : (params.row.statusID === 8 || params.row.statusID === 82 || params.row.statusID === 9) ? (
								<Grid>
									{params.row.statusID === 82 &&
										<Button
											className={`w-24`}
											variant="contained"
											color="primary"
											style={{ marginRight: 10 }}
											onClick={() => {
												setCurrentActionSDoc(params.row.id)
												setSubmitType('reject')
												setOpenSubmitSDocSureModal(true)
											}}>
											<Cancel style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ไม่อนุมัติ</Grid>
										</Button>
									}
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/SDocPreview/${params.row.id}/${myID}`}
										target="_blank"
										rel="noreferrer">
										<Button
											className={`w-40`}
											color="primary"
											variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ตัวอย่างหนังสือรับรอง</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocBillPayment/${params.row.id}`}
										rel="noreferrer"
										target="_blank"
										style={{ marginLeft: 10 }}
									>
										<Button className={`w-42`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบนำฝากชำระเงิน</Grid>
										</Button>
									</a>

									<Button
										className={`w-30`}
										style={{ marginLeft: 10 }}
										variant="contained"
										color="primary"
										onClick={() => {
											setCurrentActionSDoc(params.row.id)
											setSubmitType('updateCustomerInfo')
											setOpenSubmitSDocSureModal(true)
										}}>
										<SyncAlt style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>อัพเดทข้อมูลลูกค้าไปยังSAP</Grid>
									</Button>

									<Button
										className={`w-30`}
										style={{ marginLeft: 10 }}
										variant="contained"
										color="primary"
										onClick={() => {
											setCurrentActionSDoc(params.row.id)
											setSubmitType('resentBill')
											setOpenSubmitSDocSureModal(true)
										}}>
										<SyncAlt style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>ส่งข้อมูลใบนำฝากชำระเงินใหม่</Grid>
									</Button>
								</Grid>
							) : (
								<Grid></Grid>
							)
						)}

						{(myRole === 9) ? (
							/* ผู้ออกใบรับรอง */
							params.row.statusID === 4 ? (
								<Grid className="flex flex-row justify-start items-center gap-x-2">
									<Button
										className={`w-30`}
										color="primary"
										variant="contained"
										onClick={() => handleIssueBtn(params.row.id)}>
										<CheckCircle style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>ออกหนังสือรับรอง</Grid>
									</Button>
									<Button
										className={`w-30`}
										variant="contained"
										color="primary"
										onClick={() => {
											setCurrentActionSDoc(params.row.id)
											setSubmitType('rejectchecker')
											setOpenSubmitSDocSureModal(true)
										}}>
										<Cancel style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>ไม่อนุมัติออกหนังสือ</Grid>
									</Button>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/SDocPreview/${params.row.id}/${myID}`}
										target="_blank"
										rel="noreferrer">
										<Button
											className={`w-40`}
											color="primary"
											variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ตัวอย่างหนังสือรับรอง</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${params.row.id}/AdminGetInvoice`}
										target="_blank"
										rel="noreferrer">
										<Button className={`w-40`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบเสร็จรับเงิน</Grid>
										</Button>
									</a>
									{/* <Button
										className={`w-16`}
										variant="contained"
										color="primary"
										onClick={() => {
											onDeny(params.row.id)
										}}>
										<Cancel style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>ยกเลิก</Grid>
									</Button> */}
								</Grid>
							) : params.row.statusID === 5 ? (
								<>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocPaper/${params.row.id}`}
										target="_blank"
										rel="noreferrer">
										<Button
											className={`w-40`}
											color="primary"
											variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดหนังสือรับรอง</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${params.row.id}/AdminGetInvoice`}
										target="_blank"
										rel="noreferrer">
										<Button className={`w-40`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบเสร็จรับเงิน</Grid>
										</Button>
									</a>
								</>
							) : (params.row.statusID === 8 || params.row.statusID === 82 || params.row.statusID === 9) ? (
								<Grid>
									{params.row.statusID === 82 &&
										<Button
											className={`w-24`}
											variant="contained"
											color="primary"
											style={{ marginRight: 10 }}
											onClick={() => {
												setCurrentActionSDoc(params.row.id)
												setSubmitType('reject')
												setOpenSubmitSDocSureModal(true)
											}}>
											<Cancel style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ไม่อนุมัติ</Grid>
										</Button>
									}
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/SDocPreview/${params.row.id}/${myID}`}
										target="_blank"
										rel="noreferrer">
										<Button
											className={`w-40`}
											color="primary"
											variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ตัวอย่างหนังสือรับรอง</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocBillPayment/${params.row.id}`}
										rel="noreferrer"
										target="_blank"
										style={{ marginLeft: 10 }}
									>
										<Button className={`w-42`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบนำฝากชำระเงิน</Grid>
										</Button>
									</a>
								</Grid>
							) : (
								<Grid></Grid>
							)
						) : myRole === 15 ? (
							/* เจ้าหน้าที่สูงสุด */
							(params.row.statusID === 1 && params.row.IsUploadedAttachment == true) ? (
								<>
									<Button
										className={`w-40`}
										color="primary"
										variant="contained"
										onClick={() => {
											onClickAssignAdmin(params.row.id)
										}}>
										<PlaylistAddCheck style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>เลือกผู้ตรวจสอบข้อมูล</Grid>
									</Button>
								</>
							) : params.row.statusID === 2 ||
								params.row.statusID === 3 ? (
								// Check if have other Button except Detail Button
								params.row.statusID === 3 ? (
									// Status 3 and 5 have Cancel Button
									<>
										{!(params.row.sDocTransactions && params.row.sDocTransactions?.invoiceNo !== null) &&
											<Button
												className={`w-24`}
												variant="contained"
												color="primary"
												onClick={() => {
													setCurrentActionSDoc(params.row.id)
													setSubmitType('reject')
													setOpenSubmitSDocSureModal(true)
												}}>
												<Cancel style={{ fontSize: 16 }} className="mr-1" />
												<Grid className={`text-xs`}>ไม่อนุมัติ</Grid>
											</Button>
										}
									</>
								) : (
									<Grid className="flex flex-row justify-start items-center gap-x-2">
										<Button
											className={`w-24`}
											color="primary"
											variant="contained"
											onClick={() => {
												// onRequestAddition(params.row.id)
												setCurrentActionSDoc(params.row.id)
												setSubmitType('requestAdditionDoc')
												setOpenSubmitSDocSureModal(true)
											}}>
											<NoteAdd style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ขอเอกสารเพิ่ม</Grid>
										</Button>

										<Button
											className={`w-30`}
											color="primary"
											variant="contained"
											onClick={() => {
												setCurrentActionSDoc(params.row.id)
												setSubmitType('requestModifyInfo')
												setOpenSubmitSDocSureModal(true)
											}}>
											<NoteAdd style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ขอปรับปรุงข้อมูล</Grid>
										</Button>

										<Button
											className={`w-24 ${classes.approve_button}`}
											variant="contained"
											onClick={() => {
												setCurrentActionSDoc(params.row.id)
												setSubmitType('approve')
												setOpenSubmitSDocSureModal(true)
											}}>
											<CheckCircle style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>อนุมัติ</Grid>
										</Button>
										{!(params.row.sDocTransactions && params.row.sDocTransactions?.invoiceNo !== null) &&
											<Button
												className={`w-24`}
												variant="contained"
												color="primary"
												onClick={() => {
													setCurrentActionSDoc(params.row.id)
													setSubmitType('reject')
													setOpenSubmitSDocSureModal(true)
												}}>
												<Cancel style={{ fontSize: 16 }} className="mr-1" />
												<Grid className={`text-xs`}>ไม่อนุมัติ</Grid>
											</Button>
										}

										<Button
											className={`w-20`}
											variant="contained"
											color="primary"
											onClick={() => {
												onRejectJob(params.row.id)
											}}>
											<Cancel style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ปฏิเสธงาน</Grid>
										</Button>
										<a
											href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/SDocPreview/${params.row.id}/${myID}`}
											target="_blank"
											rel="noreferrer">
											<Button
												className={`w-40`}
												color="primary"
												variant="contained">
												<Description style={{ fontSize: 16 }} className="mr-1" />
												<Grid className={`text-xs`}>ตัวอย่างหนังสือรับรอง</Grid>
											</Button>
										</a>
									</Grid>
								)
							) : params.row.statusID === 4 ? (
								<Grid className="flex flex-row justify-start items-center gap-x-2">
									<Button
										className={`w-30`}
										color="primary"
										variant="contained"
										onClick={() => handleIssueBtn(params.row.id)}>
										<CheckCircle style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>ออกหนังสือรับรอง</Grid>
									</Button>
									<Button
										className={`w-30`}
										variant="contained"
										color="primary"
										onClick={() => {
											setCurrentActionSDoc(params.row.id)
											setSubmitType('rejectchecker')
											setOpenSubmitSDocSureModal(true)
										}}>
										<Cancel style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>ไม่อนุมัติออกหนังสือ</Grid>
									</Button>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/SDocPreview/${params.row.id}/${myID}`}
										target="_blank"
										rel="noreferrer">
										<Button
											className={`w-40`}
											color="primary"
											variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ตัวอย่างหนังสือรับรอง</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${params.row.id}/AdminGetInvoice`}
										target="_blank"
										rel="noreferrer">
										<Button className={`w-40`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบเสร็จรับเงิน</Grid>
										</Button>
									</a>
									{/* 										
										<Button
											className={`w-16`}
											variant="contained"
											color="primary"
											onClick={() => {
												onDeny(params.row.id)
											}}>
											<Cancel style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ยกเลิก</Grid>
										</Button>
										 */}
								</Grid>
							) : params.row.statusID === 5 ? (
								<>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocPaper/${params.row.id}`}
										target="_blank"
										rel="noreferrer">
										<Button
											className={`w-40`}
											color="primary"
											variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดหนังสือรับรอง</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${params.row.id}/AdminGetInvoice`}
										target="_blank"
										rel="noreferrer">
										<Button className={`w-40`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบเสร็จรับเงิน</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocBillPayment/${params.row.id}`}
										rel="noreferrer"
										target="_blank"
										style={{ marginLeft: 10 }}
									>
										<Button className={`w-42`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบนำฝากชำระเงิน</Grid>
										</Button>
									</a>
									<Button
										className={`w-30`}
										style={{ marginLeft: 10 }}
										variant="contained"
										color="primary"
										onClick={() => {
											setCurrentActionSDoc(params.row.id)
											setSubmitType('updateCustomerInfo')
											setOpenSubmitSDocSureModal(true)
										}}>
										<SyncAlt style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>อัพเดทข้อมูลลูกค้าไปยังSAP</Grid>
									</Button>

								</>
							) : params.row.statusID === 8 || params.row.statusID === 82 || params.row.statusID === 9 ? (
								<Grid>
									{params.row.statusID === 82 &&
										<Button
											className={`w-24`}
											variant="contained"
											color="primary"
											style={{ marginRight: 10 }}
											onClick={() => {
												setCurrentActionSDoc(params.row.id)
												setSubmitType('reject')
												setOpenSubmitSDocSureModal(true)
											}}>
											<Cancel style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ไม่อนุมัติ</Grid>
										</Button>
									}
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/SDocPreview/${params.row.id}/${myID}`}
										target="_blank"
										rel="noreferrer">
										<Button
											className={`w-40`}
											color="primary"
											variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ตัวอย่างหนังสือรับรอง</Grid>
										</Button>
									</a>
									<a
										href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/Document/sDocBillPayment/${params.row.id}`}
										rel="noreferrer"
										target="_blank"
										style={{ marginLeft: 10 }}
									>
										<Button className={`w-42`} color="primary" variant="contained">
											<Description style={{ fontSize: 16 }} className="mr-1" />
											<Grid className={`text-xs`}>ดาวน์โหลดใบนำฝากชำระเงิน</Grid>
										</Button>
									</a>

									<Button
										className={`w-30`}
										style={{ marginLeft: 10 }}
										variant="contained"
										color="primary"
										onClick={() => {
											setCurrentActionSDoc(params.row.id)
											setSubmitType('updateCustomerInfo')
											setOpenSubmitSDocSureModal(true)
										}}>
										<SyncAlt style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>อัพเดทข้อมูลลูกค้าไปยังSAP</Grid>
									</Button>

									<Button
										className={`w-30`}
										style={{ marginLeft: 10 }}
										variant="contained"
										color="primary"
										onClick={() => {
											setCurrentActionSDoc(params.row.id)
											setSubmitType('resentBill')
											setOpenSubmitSDocSureModal(true)
										}}>
										<SyncAlt style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>ส่งข้อมูลใบนำฝากชำระเงินใหม่</Grid>
									</Button>

									<Button
										className={`w-30`}
										style={{ marginLeft: 10 }}
										variant="contained"
										color="primary"
										onClick={() => {
											setCurrentActionSDoc(params.row.id)
											setSubmitType('BackToPreapprove')
											setOpenSubmitSDocSureModal(true)
										}}>
										<ArrowBack style={{ fontSize: 16 }} className="mr-1" />
										<Grid className={`text-xs`}>ย้อนคืนสถานะ</Grid>
									</Button>

								</Grid>
							) : (
								<Grid></Grid>
							)

						) : (
							<Grid></Grid>
						)}
					</Grid>
				)
			},
		},
	]

	return (
		<>
			<Grid className="flex flex-col">
				<Grid className="py-10 px-3 sm:px-10">
					<Card className={`border-t-4 border-card_top_border`}>
						<Grid className="flex flex-col justify-center items-start overflow-auto mb-5">
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Grid>
									<ListAlt style={{ fontSize: 22 }} className="mr-1" />
								</Grid>
								<Grid className="font-bold text-sm text-card_header_color">
									แบบหนังสือรับรองตนเอง
								</Grid>
							</Grid>
							<Grid className="flex flex-row items-center mt-4 mb-3 px-5">
								<Button
									className={`w-36`}
									color="primary"
									variant="contained"
									onClick={toggleDialog}>
									<Search style={{ fontSize: 16 }} className="mr-1" />
									<Grid className={`text-xs`}>ค้นหาขั้นสูง</Grid>
								</Button>
								{myRole === 15 && (
									<FormControlLabel
										control={
											<Switch
												className={`w-36`}
												color="primary"
												variant="contained"
												onClick={handleSwitch}
												checked={isShowFinished}
											/>
										}
										label="แสดงรายการที่อนุมัติแล้ว"
										labelPlacement="start"
									/>
								)}
							</Grid>
							<Grid className="md:px-5" style={{ height: '80vh', width: '100%' }}>
								<DataGrid
									rows={rowsWaitStatus}
									columns={columnsWaitingStatus}
									pageSize={10}
									rowsPerPageOptions={[10]}
									disableSelectionOnClick
									sortModel={sortModel}
									rowHeight={66}
									onSortModelChange={(model) => {
										handleSortChange(model)
									}}
									components={{
										NoRowsOverlay: () => {
											if (!isDataLoading) {
												return <GridOverlay>ไม่พบข้อมูล</GridOverlay>;
											} else {
												return <GridOverlay>กำลังประมวลผลข้อมูล โปรดรอสักครู่..</GridOverlay>;
											}
										}
									}}
								/>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>

			<SureModal
				open={openSubmitSDocSureModal}
				title={sureModalTitle}
				content={contentSureModal}
				onClose={() => {
					setOpenSubmitSDocSureModal(false)
				}}
				handleButton={onSubmitApproval}
				handleCancel={() => {
					setOpenSubmitSDocSureModal(false)
				}}
			/>

			<SelectAssignAdminModal
				open={openModalAssignAdmin}
				onClose={onOpenModalAssignAdmin}
				handleButton={onAssignAdmin}
				onChangeCheckerAdminID={onChangeCheckerAdminID}
				checkerAdminList={checkerAdminList}
				checkerAdminID={checkerAdminID}
			/>

			<SignSignatureModal
				open={openModalAssignSignature}
				onClose={() => setOpenModalAssignSignature(false)}
				handleButton={onAssignSignature}
				onChangeCheckerAdminID={onChangeSignature}
				checkerAdminList={signatureAdminList}
				checkerAdminID={selectedSignature}
			/>

			<Dialog
				className="w-full"
				maxWidth="md"
				fullWidth
				scroll="paper"
				open={isDialogOpen}
				onClose={toggleDialog}>
				<DialogTitle style={{ backgroundColor: '#a74345' }}>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center">
						<Grid className="flex flex-row items-center" component="div">
							<Grid className="text-white">ค้นหาขั้นสูง</Grid>
						</Grid>
						<IconButton onClick={toggleDialog} style={{ color: '#fff' }}>
							<Close />
						</IconButton>
					</Grid>
				</DialogTitle>
				<DialogContent dividers>
					<table
						className="w-full"
						style={{ textAlign: 'left', borderCollapse: 'collapse' }}
					>
						<tbody>
							<tr>
								<td style={{ width: "20%" }}>เลขที่คำขอ:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'runningNumber')?.value || ''}
										placeholder="(ไม่ต้องใส่ SR นำหน้า)"
										id="runningNumber"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ตราอักษร:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'brandName')?.value || ''}
										id="brandName"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>รุ่นของผลิตภัณฑ์:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'productModel')?.value || ''}
										id="productModel"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ผู้ยื่นเอกสาร:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'operator')?.value || ''}
										id="operator"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ยื่นในนาม:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'operatorInfo')?.value || ''}
										id="operatorInfo"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>ผู้ได้รับมอบหมายงาน:</td>
								<td>
									<TextField
										className="w-full"
										value={searchQuery.find(query => query.id === 'approver')?.value || ''}
										id="approver"
										onChange={handleSearchChange}
									/>
								</td>
							</tr>
							<tr>
								<td>วันที่ยื่นแบบ:</td>
								<td>
									<TextField
										style={{ maxWidth: '200px', width: '200px' }}
										type="date"
										value={searchCreate}
										onChange={handleCreateChange}
									/>
								</td>
							</tr>
							<tr>
								<td>วันที่อัพเดทล่าสุด:</td>
								<td>
									<TextField
										style={{ maxWidth: '200px', width: '200px' }}
										type="date"
										value={searchLastUpdated}
										onChange={handleLastUpdatedChange}
									/>
								</td>
							</tr>
							<tr>
								<td>สถานะ:</td>
								<td className="w-full ">
									<Select
										style={{ maxWidth: '600px', width: '600px' }}
										value={selectedStatus}
										onChange={handleStatusChange}
										multiple
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left"
											},
											getContentAnchorEl: null
										}}
										renderValue={(selected) => {
											const selectedLabels = selected.map(value => {
												const selectedOption = statusOptions.find(option => option.value === value);
												return selectedOption ? selectedOption.label : '';
											});
											return selectedLabels.join(', ');
										}}
									>
										{statusOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												<Checkbox checked={selectedStatus.indexOf(option.value) > -1} />
												<ListItemText primary={option.label} />
												{/* {option.label} */}
											</MenuItem>
										))}
									</Select>
								</td>
							</tr>
							<tr>
								<td>บันทึกข้อมูลการค้นหา:</td>
								<td className="w-full ">
									<Switch
										checked={isSaveSearch}
										onChange={handleSaveSearch}
										color="primary"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</DialogContent>
				<DialogActions>
					<Grid
						className="w-8/12 mx-auto flex flex-row justify-between items-center"
						component="div">
						<Button
							className="w-5/12"
							onClick={onSearchResultClear}
							color="secondary"
							variant="outlined">
							ล้างผลการค้นหา
						</Button>
						<Button
							className="w-5/12"
							color="primary"
							variant="contained"
							onClick={onSearchResult}>
							ค้นหา
						</Button>
					</Grid>
				</DialogActions>
			</Dialog>

		</>
	)
}
